import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { getToken, setToken } from '../utils/auth';
import './CompleteProfilePage.css';

// 추후 첫 로그인 때 관심사 입력하는 팝업 기능 구현 쪽으로 수정할 예정
const CompleteProfilePage = () => {
  const [nickname, setNickname] = useState('');
  const [phonenumber, setPhonenumber] = useState('');
  const [currentstate, setCurrentstate] = useState('');
  const [nicknameChecked, setNicknameChecked] = useState(false);
  const [nicknameError, setNicknameError] = useState('');

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');
    if (tokenFromUrl) {
      setToken(tokenFromUrl);  // Save token in session storage
    }
  }, []);

  const handleCompleteProfile = async (e) => {
    e.preventDefault();
    if (!nicknameChecked) {
      setNicknameError('닉네임 중복 확인을 해주세요.');
      return;
    }
    try {
      const token = getToken();  // Get token from session storage
      const response = await axios.post('http://localhost:3001/auth/complete-profile', 
        { nickname, phonenumber, currentstate },
        { headers: {           
          'Content-Type': 'application/json',
          'x-auth-token': token} 
        }
      );
      if (response.status === 200) {
        alert('프로필이 완료되었습니다.');
        window.location.href = '/main';
      }
    } catch (error) {
      alert('프로필 완료 실패');
      console.error('프로필 완료 실패:', error);
    }
  };

  const handleNicknameCheck = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:3001/auth/check-nickname', { nickname });
      if (response.status === 200) {
        setNicknameError('사용 가능한 닉네임입니다.');
        setNicknameChecked(true);
      }
    } catch (error) {
      setNicknameError(error.response.data.msg);
      setNicknameChecked(false);
    }
  };

  return (
    <div className="complete-profile-page">
      <h1>추가 정보 입력</h1>
      <form onSubmit={handleCompleteProfile}>
        <div className="nickname-container">
          <input
            type="text"
            placeholder="별칭을 입력해주세요(프로필 이름)"
            value={nickname}
            onChange={(e) => {
              setNickname(e.target.value);
              setNicknameChecked(false);
            }}
            required
          />
          <button type="button" onClick={handleNicknameCheck}>
            중복 확인
          </button>
        </div>
        {nicknameError && (
          <div className={nicknameChecked ? 'nickname-success' : 'nickname-error'}>
            {nicknameError}
          </div>
        )}
        <input
          type="text"
          placeholder="전화번호를 입력해주세요"
          value={phonenumber}
          onChange={(e) => setPhonenumber(e.target.value)}
          required
        />
        <select
          value={currentstate}
          onChange={(e) => setCurrentstate(e.target.value)}
          required
        >
          <option value="">현재 상태를 선택해주세요</option>
          <option value="학생">학생</option>
          <option value="직장인">직장인</option>
          <option value="취준생">취준생</option>
          <option value="주부">주부</option>
        </select>
        <button type="submit">프로필 등록하기</button>
      </form>
    </div>
  );
};

export default CompleteProfilePage;