import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import './SavedResumes.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const SavedResumes = () => {
  const [resumes, setResumes] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchResumes = async () => {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/user/coverletters`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setResumes(data);
        } else {
          console.error('Failed to fetch resumes');
        }
      } catch (error) {
        console.error('Error fetching resumes:', error);
      }
    };

    fetchResumes();
  }, []);

  const handleResumeClick = async (coverLetterId) => {
    try {
      const token = getToken();
      const response = await fetch(`${BACKEND_URL}/user/getCoverletter/${coverLetterId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        
        // Check if data.redirectTo is a valid URL or a relative path
        let redirectPath;
        try {
          redirectPath = new URL(data.redirectTo).pathname; // Use .pathname to get the relative path
        } catch (error) {
          // If new URL fails, assume it's a relative path
          redirectPath = data.redirectTo;
        }
  
        navigate(redirectPath, { state: { coverLetter: data.coverLetter } });
      } else {
        console.error('Failed to fetch cover letter details');
      }
    } catch (error) {
      console.error('Error fetching cover letter details:', error);
    }
  };

  const handleDelete = async (coverLetterId, event) => {
    event.stopPropagation(); // Prevent event bubbling to parent elements

    const confirmDelete = window.confirm('정말로 이 자소서를 삭제하시겠습니까?');
    if (confirmDelete) {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/user/deleteCoverletter/${coverLetterId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          alert('자소서가 성공적으로 삭제되었습니다.');
          setResumes(resumes.filter(resume => resume._id !== coverLetterId));
        } else {
          console.error('Failed to delete cover letter');
        }
      } catch (error) {
        console.error('Error deleting cover letter:', error);
      }
    }
  };

  return (
    <div className="saved-resumes">
      <div className="header">
        <Link to="/mypage" className="back-link">
          <img src="back-icon.png" alt="Back" className="back-icon" />
          저장한 파일
        </Link>
        <h2>자소서 생성기</h2>
      </div>
      <div className="resume-list">
        {resumes.map((resume) => (
          <div
            className="resume-item"
            key={resume._id}
            onClick={() => handleResumeClick(resume._id)} // Add click event to the whole div
          >
            <span>
              {resume.infoStatus === 'draft' || resume.contentStatus === 'draft' ? '[임시저장] ' : ''}
              {resume.title}
            </span>
            <span>{new Date(resume.updatedDt).toLocaleDateString()}</span>
            <button className="delete-button" onClick={(event) => handleDelete(resume._id, event)}>삭제</button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SavedResumes;