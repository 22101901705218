import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Pagination from './Pagination';
import './PostList.css';
import { getToken } from '../../utils/auth';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PostList = ({ category, sortBy }) => {
  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalItems, setTotalItems] = useState(0);
  const [totalPages, setTotalPages] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const itemsPerPage = 10;
  const token = getToken();

  useEffect(() => {
    fetchPosts();
  }, [currentPage, category, sortBy]);

  const fetchPosts = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const response = await fetch(`${BACKEND_URL}/posts/list?page=${currentPage}&limit=${itemsPerPage}&category=${category || ''}&sortBy=${sortBy}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });

      if (!response.ok) {
        throw new Error('게시판을 가져오는데 실패했습니다');
      }

      const data = await response.json();
      setPosts(data.posts);
      setTotalItems(data.totalItems);
      setTotalPages(data.totalPages);
    } catch (error) {
      console.error('게시판 가져오기 오류:', error);
      setError(error.message);
    } finally {
      setIsLoading(false);
    }
  };

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  if (isLoading) {
    return <div>로딩 중...</div>;
  }

  if (error) {
    return <div>에러 발생: {error}</div>;
  }

  return (
    <div className="post-list">
      {posts && posts.length > 0 ? (
        posts.map((post) => (
          <Link to={`/community/post/${post._id}`} key={post._id} className="post-item">
            <h2>{post.title}</h2>
            <p className="post-excerpt">{post.text.substring(0, 100)}...</p>
            <div className="post-meta">
              <span className="author">{post.author}</span>
              <span className="date">{new Date(post.createdAt).toLocaleDateString()}</span>
              <span className="likes">👍 {post.likeCount}</span>
              <span className="comments">💬 {post.commentCount}</span>
            </div>
          </Link>
        ))
      ) : (
        <div className="no-posts">게시물이 없습니다.</div>
      )}
      {totalPages > 1 && (
        <Pagination 
          currentPage={currentPage}
          totalItems={totalItems}
          itemsPerPage={itemsPerPage}
          onPageChange={handlePageChange}
        />
      )}
    </div>
  );
};

export default PostList;