import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Document, Packer, Paragraph, TextRun } from 'docx';  // word 내보내기
import { saveAs } from 'file-saver';  // word 내보내기 기능
import './ResumeViewPage.css';
import { getToken } from '../../../utils/auth';

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ResumeViewPage = () => {
  const { coverLetterId } = useParams();
  const navigate = useNavigate();
  const [title, setTitle] = useState('');
  const [content, setContent] = useState([]);
  const [copyStatus, setCopyStatus] = useState({});
  const [charCounts, setCharCounts] = useState({});

  useEffect(() => {
    const fetchCoverLetter = async () => {
      try {
        const token = getToken();
        if (!token) {
          throw new Error('No token found');
        }

        const response = await axios.get(`${BACKEND_URL}/tool1/${coverLetterId}`, {
          headers: {
            'x-auth-token': token
          }
        });

        const coverLetterData = response.data;
        setTitle(coverLetterData.title);

        // content는 배열로 전송되므로 그대로 사용
        setContent(coverLetterData.content || []);
        const CharCounts = {};
        coverLetterData.content.forEach((c, i) => {
          const totalLength = c.answer.length;
          const noSpaceLength = c.answer.replace(/\s/g, '').length;
          CharCounts[i] = { total: totalLength, noSpace: noSpaceLength };
        });
        setCharCounts(CharCounts);
      } catch (error) {
        console.error('Error fetching cover letter:', error);
      }
    };

    fetchCoverLetter();
  }, [coverLetterId]);

  // 수정하기 버튼을 눌렀을 때, 질문과 응답을 다시 넘겨주도록 수정
  const handleEdit = () => {
    navigate(`/tools/resume-generator/generate/${coverLetterId}`, { state: { content } });
  };

  const handleExportWord = () => {
    // Create a new document
    const doc = new Document({
      sections: [ // Ensure that `sections` is an array
        {
          properties: {}, // You can define section properties if needed
          children: [
            new Paragraph({
              children: [new TextRun({ text: `제목: ${title}`, bold: true, size: 30 })],
            }),
            new Paragraph({}),
            ...content
              .map(({ question, answer }, index) => [
                new Paragraph({
                  children: [
                    new TextRun({ text: `질문${index + 1}: ${question}`, bold: true, size: 25 }),
                  ],
                }),
                new Paragraph({
                  children: [new TextRun({ text: `${answer}` })],
                }),
                new Paragraph({
                  children: [new TextRun({ text: '\n\n' })],
                }),
              ])
              .flat(),
          ],
        },
      ],
    });
  
    // Export document as a blob
    Packer.toBlob(doc).then((blob) => {
      saveAs(blob, 'cover_letter.docx');
    });
  };

  const handleCopy = (index, text) => {
    navigator.clipboard.writeText(text).then(() => {
      setCopyStatus((prevStatus) => ({
        ...prevStatus,
        [index]: true
      }));
      setTimeout(() => {
        setCopyStatus((prevStatus) => ({
          ...prevStatus,
          [index]: false
        }));
      }, 5000);
    }).catch((error) => {
      console.error('Error copying text:', error);
    });
  };

  return (
    <div className="resume-view-page">
      <h1>자소서생성기</h1>
      <div className="cover-letter-view">
        {/* 제목 입력란 */}
        <div className="resumeview-title-container">
          <div className='resumeview-title'>제목</div>
          <input
            className="resumeview-title-input"
            id="title"
            type="text"
            value={title}
            readOnly
          />
        </div>

        {/* 자기소개서 내용 */}
        <div className="cover-letter-content">
          {content.map(({ question, answer }, index) => (
            <div className="resumeview-container">
                {/* 각 텍스트박스별 글자 수 */}
                <div className="char-count-container">
                  <span>공백 포함 {charCounts[index]?.total || 0}자</span>&nbsp;|&nbsp;
                  <span>공백 미포함 {charCounts[index]?.noSpace || 0}자</span>
                </div>
              <div key={index} className="qa-container">
                <div className="qa-header">
                  <h3>질문: {question}</h3>
                  <button
                    onClick={() => handleCopy(index, answer)}
                    className={`resumeview-copy-button ${copyStatus[index] ? 'copied' : ''}`}
                  >
                    {copyStatus[index] ? '복사됨' : '복사'}
                  </button>
                </div>
                <p>{answer}</p>
              </div>
            </div>
          ))}
        </div>
        {/* 하단 버튼들 */}
        <div className="ResumeView-action-buttons">
          <button onClick={handleEdit}>수정하기</button>
          <div className="export-buttons">
            <button onClick={handleExportWord}>word로 내보내기</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResumeViewPage;