import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import { FaArrowLeft } from 'react-icons/fa';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';
import './ResumeEvaluationDetail.css';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ResumeEvaluationDetail = () => {
    const navigate = useNavigate();
    const [evaluation, setEvaluation] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const { id } = useParams();

    useEffect(() => {
        const fetchEvaluationDetail = async () => {
            try {
                const token = getToken();
                console.log('Fetching evaluation with ID:', id);
                const response = await axios.get(`${BACKEND_URL}/resume-evaluation/${id}`, {
                    headers: { 'x-auth-token': token }
                });
                console.log('Received evaluation data:', response.data);
                setEvaluation(response.data.evaluation);
            } catch (error) {
                console.error('Error fetching evaluation detail:', error.response?.data || error.message);
                setError('평가 상세 정보를 불러오는 중 오류가 발생했습니다.');
            } finally {
                setLoading(false);
            }
        };

        fetchEvaluationDetail();
    }, [id]);

    const handleGoBack = () => {
        navigate('/mypage/saved-resume-evaluations');
    };

    const renderRadarChart = () => {
        if (!evaluation || !evaluation.graphData) return null;

        const data = {
            labels: evaluation.graphData.map(item => item.category),
            datasets: [{
                label: '점수',
                data: evaluation.graphData.map(item => item.score),
                backgroundColor: 'rgba(255, 99, 132, 0.2)',
                borderColor: 'rgba(255, 99, 132, 1)',
                borderWidth: 1
            }]
        };

        const options = {
            scales: {
                r: {
                    beginAtZero: true,
                    max: 25
                }
            }
        };

        return <Radar data={data} options={options} />;
    };

    if (loading) return <div>로딩 중...</div>;
    if (error) return <div>{error}</div>;
    if (!evaluation) return <div>평가 정보를 찾을 수 없습니다.</div>;

    return (
        <div className="resume-evaluation-detail">
            <div className="header">
                <button onClick={handleGoBack} className="back-link">
                    <FaArrowLeft className="back-icon" />
                    <span>목록으로 돌아가기</span>
                </button>
                <h2>{evaluation.title}</h2>
            </div>
            <div className="evaluation-content">
                <div className="evaluation-section">
                    <h3>기본 정보</h3>
                    <p><strong>지원 분야:</strong> {evaluation.field}</p>
                    <p className="total-score">총점: {evaluation.totalScore}</p>
                    <p className="fit-for-role">적합도: {evaluation.fitForRole}</p>
                </div>
                <div className="evaluation-section">
                    <h3>점수 상세</h3>
                    <ul className="score-list">
                        <li><span className="score-name">관련성:</span> <span className="score-value">{evaluation.scores.relevance}</span></li>
                        <li><span className="score-name">성과:</span> <span className="score-value">{evaluation.scores.achievements}</span></li>
                        <li><span className="score-name">기술:</span> <span className="score-value">{evaluation.scores.skills}</span></li>
                        <li><span className="score-name">교육:</span> <span className="score-value">{evaluation.scores.education}</span></li>
                        <li><span className="score-name">전반적인 구성:</span> <span className="score-value">{evaluation.scores.presentation}</span></li>
                    </ul>
                </div>
                <div className="evaluation-section">
                    <h3>전반적인 평가</h3>
                    <p>{evaluation.overallEvaluation}</p>
                </div>
                <div className="evaluation-section">
                    <h3>강점</h3>
                    <ul className="strength-list">
                        {evaluation.strengths.map((strength, index) => (
                            <li key={index}>{strength}</li>
                        ))}
                    </ul>
                </div>
                <div className="evaluation-section">
                    <h3>개선 필요 사항</h3>
                    <ul className="improvement-list">
                        {evaluation.areasForImprovement.map((area, index) => (
                            <li key={index}>{area}</li>
                        ))}
                    </ul>
                </div>
                <div className="evaluation-section">
                    <h3>개선 제안</h3>
                    <ul className="improvement-list">
                        {evaluation.suggestedImprovements.map((suggestion, index) => (
                            <li key={index}>{suggestion}</li>
                        ))}
                    </ul>
                </div>
                <div className="evaluation-section">
                    {renderRadarChart()}
                </div>
            </div>
        </div>
    );
};

export default ResumeEvaluationDetail;