import React, { useState } from 'react';
import './RecruitInfoPage.css';
import recruitData from './recruitData'; // 데이터 파일 가져오기

const RecruitInfoPage = () => {
  const [recruitList, setRecruitList] = useState(recruitData); // 공고 리스트 상태
  const [selectedLocations, setSelectedLocations] = useState([]); // 선택된 지역들
  const [showLocationModal, setShowLocationModal] = useState(false); // 지역 선택 모달 상태
  const [tempSelectedLocations, setTempSelectedLocations] = useState([]); // 모달에서 선택된 임시 지역 상태
  const [showCareerModal, setShowCareerModal] = useState(false); // 신입/경력 모달 상태
  const [tempSelectedCareer, setTempSelectedCareer] = useState(''); // 모달에서 선택된 임시 경력 상태
  const [careerFilter, setCareerFilter] = useState(''); // 경력 필터 상태
  const [minExperience, setMinExperience] = useState(''); // 최소 연차 상태
  const [maxExperience, setMaxExperience] = useState(''); // 최대 연차 상태
  const [showEducationModal, setShowEducationModal] = useState(false); // 학력 모달 상태
  const [tempSelectedEducation, setTempSelectedEducation] = useState(''); // 모달에서 선택된 임시 학력 상태
  const [educationFilter, setEducationFilter] = useState(''); // 학력 필터 상태

  // 관심 공고 토글 핸들러 함수
  const toggleFavorite = (id) => {
    setRecruitList((prevList) =>
      prevList.map((item) =>
        item.id === id ? { ...item, isFavorite: !item.isFavorite } : item
      )
    );
  };

  // 지역 선택 핸들러
  const handleLocationSelect = (location) => {
    setTempSelectedLocations((prevSelected) =>
      prevSelected.includes(location)
        ? prevSelected.filter((item) => item !== location)
        : [...prevSelected, location]
    );
  };

  // 경력 선택 핸들러 (한 번에 하나만 선택 가능)
  const handleCareerSelect = (career) => {
    setTempSelectedCareer(career);
  };

  // 학력 선택 핸들러 (한 번에 하나만 선택 가능)
  const handleEducationSelect = (education) => {
    setTempSelectedEducation(education);
  };

  // 최소 연차 입력 핸들러
  const handleMinExperienceChange = (e) => {
    const value = e.target.value;
    if (value === '' || (parseInt(value) > 0 && (parseInt(value) < parseInt(maxExperience) || maxExperience === ''))) {
      setMinExperience(value);
    }
  };

  // 최대 연차 입력 핸들러
  const handleMaxExperienceChange = (e) => {
    const value = e.target.value;
    if (value === '' || (parseInt(value) > parseInt(minExperience) && parseInt(value) > 0)) {
      setMaxExperience(value);
    }
  };

  // 지역 모달 적용 버튼 핸들러
  const applyLocationFilter = () => {
    setSelectedLocations(tempSelectedLocations);
    setShowLocationModal(false);
  };

  // 경력 모달 적용 버튼 핸들러
  const applyCareerFilter = () => {
    if (tempSelectedCareer === '경력' && (minExperience === '' || maxExperience === '' || parseInt(minExperience) >= parseInt(maxExperience))) {
      alert('올바른 연차를 입력하세요.');
      return;
    }
    setCareerFilter(tempSelectedCareer);
    setShowCareerModal(false);
  };

  // 학력 모달 적용 버튼 핸들러
  const applyEducationFilter = () => {
    setEducationFilter(tempSelectedEducation);
    setShowEducationModal(false);
  };

  // 모달 초기화 핸들러
  const resetLocationFilter = () => {
    setTempSelectedLocations([]);
  };

  // 경력 초기화 핸들러
  const resetCareerFilter = () => {
    setTempSelectedCareer('');
    setMinExperience('');
    setMaxExperience('');
  };

  // 학력 초기화 핸들러
  const resetEducationFilter = () => {
    setTempSelectedEducation('');
  };

  // 필터링된 공고 목록 반환 함수
  const getFilteredRecruitList = () => {
    return recruitList.filter((item) => {
      const locationMatch = selectedLocations.length === 0 || selectedLocations.some((loc) => item.location.includes(loc));
      const careerMatch = !careerFilter || item.career.includes(careerFilter);
      const educationMatch = !educationFilter || item.education.includes(educationFilter);
      return locationMatch && careerMatch && educationMatch;
    });
  };

  // 필터링된 공고 목록
  const filteredList = getFilteredRecruitList();

  // 지역 목록 데이터
  const locationOptions = [
    '전체', '서울', '경기', '인천', '대전', '세종', '충남', '충북', 
    '광주', '전남', '전북', '대구', '경북', '부산', '울산', '경남', 
    '강원', '제주', '전국'
  ];

  // 경력 목록 데이터
  const careerOptions = ['경력무관', '신입', '경력'];

  // 학력 목록 데이터
  const educationOptions = [
    '학력무관', '고등학교졸업', '대학졸업(2,3년)', '대학교졸업(4년)', 
    '대학원 석사졸업', '대학원 박사졸업'
  ];

  return (
    <div className="recruit-info-page">
      <div className="filter-bar">
        <div className="filter-group">
          {/* 지역 선택 버튼 */}
          <div className="filter-button-group">
            <button
              className="filter-dropdown"
              onClick={() => setShowLocationModal(!showLocationModal)}
            >
              지역 {showLocationModal ? '⌃' : '⌄'}
            </button>
            {showLocationModal && (
              <div className="filter-dropdown-content">
                {locationOptions.map((location, index) => (
                  <button
                    key={index}
                    className={`location-button ${tempSelectedLocations.includes(location) ? 'selected' : ''}`}
                    onClick={() => handleLocationSelect(location)}
                  >
                    {location}
                  </button>
                ))}
                <div className="modal-footer">
                  <button className="filter-reset" onClick={resetLocationFilter}>초기화</button>
                  <button className="filter-apply" onClick={applyLocationFilter}>적용하기</button>
                </div>
              </div>
            )}
          </div>

          {/* 경력 선택 버튼 */}
          <div className="filter-button-group">
            <button
              className="filter-dropdown"
              onClick={() => setShowCareerModal(!showCareerModal)}
            >
              신입/경력 {showCareerModal ? '⌃' : '⌄'}
            </button>
            {showCareerModal && (
              <div className="filter-dropdown-content">
                {careerOptions.map((career, index) => (
                  <button
                    key={index}
                    className={`career-button ${tempSelectedCareer === career ? 'selected' : ''}`}
                    onClick={() => handleCareerSelect(career)}
                  >
                    {career}
                  </button>
                ))}
                {/* 경력 선택 시 연차 입력란 표시 */}
                {tempSelectedCareer === '경력' && (
                  <div className="experience-inputs">
                    <label>경력 <span style={{color: '#007bff'}}>전체</span></label>
                    <div className="experience-range">
                      <input
                        type="number"
                        min="1"
                        className="experience-input"
                        value={minExperience}
                        onChange={handleMinExperienceChange}
                        placeholder="최소 연차"
                      />
                      <span> ~ </span>
                      <input
                        type="number"
                        min="1"
                        className="experience-input"
                        value={maxExperience}
                        onChange={handleMaxExperienceChange}
                        placeholder="최대 연차"
                      />
                    </div>
                  </div>
                )}
                <div className="modal-footer">
                  <button className="filter-reset" onClick={resetCareerFilter}>초기화</button>
                  <button className="filter-apply" onClick={applyCareerFilter}>적용하기</button>
                </div>
              </div>
            )}
          </div>

          {/* 학력 선택 버튼 */}
          <div className="filter-button-group">
            <button
              className="filter-dropdown"
              onClick={() => setShowEducationModal(!showEducationModal)}
            >
              학력 {showEducationModal ? '⌃' : '⌄'}
            </button>
            {showEducationModal && (
              <div className="filter-dropdown-content">
                <div className="education-grid">
                  {educationOptions.map((education, index) => (
                    <button
                      key={index}
                      className={`education-button ${tempSelectedEducation === education ? 'selected' : ''}`}
                      onClick={() => handleEducationSelect(education)}
                    >
                      {education}
                    </button>
                  ))}
                </div>
                <div className="modal-footer">
                  <button className="filter-reset" onClick={resetEducationFilter}>초기화</button>
                  <button className="filter-apply" onClick={applyEducationFilter}>적용하기</button>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* 관심 공고로 이동 버튼 */}
        <button className="favorite-button" onClick={() => alert('관심공고로 이동')}>관심공고로 이동</button>
      </div>

      <div className="recruit-list">
        {/* 공고 리스트 */}
        {filteredList.map((item) => (
          <div key={item.id} className="recruit-item">
            <div className="recruit-header">
              <h3>{item.title}</h3>
              <p>{item.company}</p>
            </div>
            <div className="recruit-details">
              <span className="recruit-tag">{item.location}</span>
              <span className="recruit-tag">{item.career}</span>
              <span className="recruit-tag">{item.education}</span>
            </div>
            <div className="recruit-footer">
              <div className="recruit-date">
                <p>공고 {item.postDate}</p>
                <p>모집 ~{item.dueDate}</p>
              </div>
              <button
                className={`favorite-toggle ${item.isFavorite ? 'active' : ''}`}
                onClick={() => toggleFavorite(item.id)}
              >
                {item.isFavorite ? '★' : '☆'} 관심공고
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecruitInfoPage;