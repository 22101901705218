import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import './SavedTechTrees.css';
import { getToken } from '../../utils/auth';
import { FaArrowLeft } from 'react-icons/fa';  // react-icons 라이브러리에서 아이콘 import

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const SavedTechTrees = () => {
  const [techTrees, setTechTrees] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchTechTrees = async () => {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/user/tech-trees`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTechTrees(data);
        } else {
          console.error('테크트리 목록을 가져오는데 실패했습니다');
        }
      } catch (error) {
        console.error('테크트리 목록 가져오기 오류:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTechTrees();
  }, []);

  const handleTechTreeClick = (techTreeId) => {
    navigate(`/tools/tech-tree/${techTreeId}`);
  };

  // 테크트리 삭제 함수
  const handleDelete = async (techTreeId, event) => {
    event.stopPropagation(); // 클릭 이벤트가 상위 요소로 전파되는 것을 막음
    const confirmDelete = window.confirm('정말로 이 테크트리를 삭제하시겠습니까?');
    
    if (confirmDelete) {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/user/deleteTechTree/${techTreeId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          alert('테크트리가 성공적으로 삭제되었습니다.');
          setTechTrees(techTrees.filter(tree => tree._id !== techTreeId)); // 삭제된 테크트리를 목록에서 제거
        } else {
          console.error('테크트리 삭제에 실패했습니다');
        }
      } catch (error) {
        console.error('테크트리 삭제 오류:', error);
      }
    }
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  return (
    <div className="saved-tech-trees">
      <div className="header">
        <Link to="/mypage" className="back-link">
          <FaArrowLeft className="back-icon" />
          <span className="back-text">돌아가기</span>
        </Link>
        <h2>테크트리 생성기</h2>
      </div>
      <div className="tech-tree-list">
        {techTrees.map((techTree) => (
          <div 
            className="tech-tree-item" 
            key={techTree._id}
            onClick={() => handleTechTreeClick(techTree._id)}
          >
            <span>{techTree.jobTitle} - {techTree.role}</span>
            <span>{new Date(techTree.createdDt).toLocaleDateString()}</span>
            <button 
              className="delete-button"
              onClick={(event) => handleDelete(techTree._id, event)}
            >
              삭제
            </button>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SavedTechTrees;