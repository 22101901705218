// fields.js
const fields = {
    "IT/기술": [
      "웹 개발", "모바일 개발", "소프트웨어 개발", "게임 개발", "인공지능(AI)", "머신러닝(ML)", 
      "데이터 사이언스", "데이터 엔지니어링", "빅데이터 분석", "데이터베이스 관리", "클라우드 컴퓨팅", 
      "사이버 보안", "네트워크 관리", "블록체인 개발", "시스템 엔지니어링", "IoT (사물인터넷)", 
      "IT 인프라 관리", "IT 컨설팅", "DevOps"
    ],
    "마케팅/광고": [
      "디지털 마케팅", "소셜 미디어 마케팅", "콘텐츠 마케팅", "이메일 마케팅", "브랜드 마케팅", 
      "퍼포먼스 마케팅", "인플루언서 마케팅", "검색 엔진 최적화(SEO)", "검색 엔진 마케팅(SEM)", 
      "광고 기획 및 전략", "시장 조사 및 분석", "이벤트 마케팅", "프로덕트 마케팅", 
      "고객 유입 경로 분석", "마케팅 자동화", "PR (Public Relations)"
    ],
    "디자인": [
      "UX/UI 디자인", "그래픽 디자인", "웹 디자인", "제품 디자인", "서비스 디자인", 
      "인터랙션 디자인", "모션 그래픽 디자인", "시각 디자인", "패키지 디자인", "일러스트레이션", 
      "3D 모델링", "게임 아트 디자인", "광고 디자인"
    ],
    "경영/관리": [
      "프로젝트 관리", "제품 관리", "인사 관리", "운영 관리", "전략 기획", 
      "재무 관리", "비즈니스 개발", "리스크 관리", "공급망 관리", "품질 관리", 
      "변화 관리", "고객 경험 관리"
    ],
    "교육/연구": [
      "교육 기획 및 운영", "온라인 교육 콘텐츠 개발", "학습 프로그램 개발", "교육 평가 및 분석", 
      "교육 컨설팅", "커리큘럼 설계", "교육 기술 개발", "연구 및 분석", "R&D (Research & Development)", 
      "학습 데이터 분석"
    ],
    "의료/보건": [
      "임상 연구", "병원 관리", "환자 치료 및 관리", "의료 데이터 분석", "공공 보건", 
      "의료 기기 연구", "심리 치료", "건강 증진 프로그램", "간호 관리", "재활 프로그램 관리"
    ],
    "영업/고객 관리": [
      "영업 기획", "B2B 영업", "B2C 영업", "고객 지원 및 서비스", "고객 성공 관리", 
      "CRM (고객 관계 관리)", "판매 전략", "거래 관리", "영업 데이터 분석"
    ],
    "법률/법무": [
      "법률 자문", "계약 관리", "기업 법무", "소송 관리", "준법 감시", 
      "지적 재산권 관리", "법률 리서치", "규제 준수 관리", "법적 리스크 관리"
    ],
    "재무/회계": [
      "회계 관리", "재무 분석", "투자 관리", "세무 관리", "내부 감사", 
      "자산 관리", "보험 관리", "기업 재무", "비용 관리", "금융 리스크 관리", 
      "세금 전략"
    ],
    "엔지니어링": [
      "기계 엔지니어링", "전기 엔지니어링", "전자 엔지니어링", "화학 엔지니어링", "건축 엔지니어링", 
      "토목 엔지니어링", "환경 엔지니어링", "제조 엔지니어링", "항공우주 엔지니어링", "로봇 공학", 
      "산업 공학", "품질 공학"
    ],
    "제조/생산": [
      "제조 관리", "생산 기획", "공정 설계", "품질 관리", "공급망 관리", 
      "유지보수 관리", "생산 최적화", "설비 관리", "자재 관리"
    ],
    "미디어/저널리즘": [
      "기자", "콘텐츠 제작", "방송 기획", "뉴스 편집", "라디오 제작", 
      "동영상 콘텐츠 제작", "포토그래피", "뉴스 앵커", "편집 기획", "미디어 분석"
    ],
    "물류/운송": [
      "물류 기획", "운송 관리", "창고 관리", "공급망 분석", "국제 무역 관리", 
      "수출입 관리", "배송 관리", "재고 관리", "물류 최적화"
    ],
    "서비스/접객": [
      "호텔 관리", "여행 기획", "고객 서비스 운영", "이벤트 기획", "리셉션 관리", 
      "외식 서비스 관리", "바리스타 운영", "매장 관리", "서비스 교육"
    ],
    "농업/환경": [
      "농업 기획 및 관리", "환경 관리", "자원 관리", "지속 가능성 전략", "생태학 연구", 
      "농업 기술 연구", "산림 관리", "수산업 관리", "식품 안전 관리", "에너지 효율 연구"
    ],
    "예술/엔터테인먼트": [
      "영화 제작", "방송 프로듀싱", "연출 기획", "배우 관리", "음악 제작", 
      "공연 연출", "게임 디자인", "음향 엔지니어링", "무대 디자인"
    ]
  };
  
  export default fields;