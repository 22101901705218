import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import './WritePost.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const WritePost = () => {
  const [title, setTitle] = useState('');
  const [text, setText] = useState('');
  const [category, setCategory] = useState('');
  const navigate = useNavigate();
  const token = getToken();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${BACKEND_URL}/posts/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({ title, text, category }),
      });

      if (response.ok) {
        navigate('/community');
      } else {
        const errorData = await response.json();
        alert(errorData.message);
      }
    } catch (error) {
      console.error('글 작성 중 오류 발생:', error);
      alert('글 작성 중 오류가 발생했습니다.');
    }
  };

  return (
    <form onSubmit={handleSubmit} className="write-post-form">
      <input
        type="text"
        value={title}
        onChange={(e) => setTitle(e.target.value)}
        placeholder="제목"
        required
      />
      <textarea
        value={text}
        onChange={(e) => setText(e.target.value)}
        placeholder="내용"
        required
      />
      <select
        value={category}
        onChange={(e) => setCategory(e.target.value)}
        required
      >
        <option value="">카테고리 선택</option>
        <option value="문의">문의</option>
        <option value="신청">신청</option>
        <option value="고민">고민</option>
        <option value="학사">학사</option>
        <option value="여행">여행</option>
        <option value="Q&A">Q&A</option>
        <option value="자유">자유</option>
      </select>
      <button type="submit">글 작성</button>
    </form>
  );
};

export default WritePost;