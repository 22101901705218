import React, { useState } from 'react';
import standardImage from '../../../assets/images/photosgenerator/standard.png';
import irregularImage from '../../../assets/images/photosgenerator/irregular.png';
import naturalImage from '../../../assets/images/photosgenerator/natural.png';
import officeImage from '../../../assets/images/photosgenerator/office.png';
import warmOfficeImage from '../../../assets/images/photosgenerator/warmoffice.png';

const photoOptions = [
  { style: 'standard', label: '증명 사진 (3.5 x 4.5)', src: standardImage },
  // 공식 문서나 비즈니스용 ID 카드에 적합한 스타일의 이미지가 필요합니다.
  { style: 'formal', label: '공식 문서용', src: irregularImage },
  // 좀 더 편안하고 자연스러운 스타일의 이미지가 필요합니다.
  { style: 'casual', label: '캐주얼한 프로필', src: naturalImage },
  // 전문직 종사자에게 적합한 스타일의 이미지가 필요합니다.
  { style: 'professional', label: '전문가 프로필', src: officeImage },
  // 학생증이나 교직원 카드에 적합한 스타일의 이미지가 필요합니다.
  { style: 'academic', label: '학술용 프로필', src: warmOfficeImage },
  // 여권 사진 규격에 맞는 스타일의 이미지가 필요합니다.
  { style: 'passport', label: '여권용 사진', src: standardImage },
  // 회사 ID 카드에 적합한 스타일의 이미지가 필요합니다.
  { style: 'corporate', label: '기업 ID 카드용', src: officeImage },
  // 공무원 신분증 등에 적합한 스타일의 이미지가 필요합니다.
  { style: 'government', label: '공무원 신분증용', src: irregularImage },
];

const OptionsStep = ({ gender, setGender, style, setStyle, handleGeneratePhotos, handlePrevStep, loading }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const handlePrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex > 0 ? prevIndex - 1 : 0));
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex < photoOptions.length - 3 ? prevIndex + 1 : prevIndex));
  };

  return (
    <div className="options-step">
      <h1 className="options-step__title">증명사진 생성기</h1>
      <h2 className="options-step__subtitle">옵션 선택 (2/2 Step)</h2>
      <div className="options-step__section">
        <label className="options-step__label">성별 선택 <span className="options-step__required">*</span></label>
        <div className="options-step__radio-group">
          <label className="options-step__radio-option">
            <input type="radio" name="gender" value="female" checked={gender === 'female'} onChange={() => setGender('female')} />
            여성
          </label>
          <label className="options-step__radio-option">
            <input type="radio" name="gender" value="male" checked={gender === 'male'} onChange={() => setGender('male')} />
            남성
          </label>
        </div>
      </div>
      <div className="options-step__section">
        <label className="options-step__label">스타일 선택 <span className="options-step__required">*</span></label>
        <div className="options-step__style-slider">
          <button className="options-step__nav-button" onClick={handlePrev} disabled={currentIndex === 0}>&lt;</button>
          <div className="options-step__style-container" style={{ transform: `translateX(-${currentIndex * 33.333}%)` }}>
            {photoOptions.map((option, index) => (
              <label
                key={index}
                className={`options-step__style-option ${style === option.style ? 'selected' : ''}`}
                onClick={() => setStyle(option.style)}
              >
                <img src={option.src} alt={option.label} />
                <span>{option.label}</span>
              </label>
            ))}
          </div>
          <button className="options-step__nav-button" onClick={handleNext} disabled={currentIndex >= photoOptions.length - 3}>&gt;</button>
        </div>
      </div>
      <div className="options-step__buttons">
        <button className="options-step__button" onClick={handlePrevStep}>
          이전
        </button>
        <button 
          className={`options-step__button ${gender && style ? '' : 'disabled'}`} 
          onClick={handleGeneratePhotos} 
          disabled={loading || !gender || !style}
        >
          {loading ? '사진을 생성하고 있습니다..' : '생성'}
        </button>
      </div>
      {loading && (
        <div className="options-step__loading-overlay">
          <div className="options-step__loading-text">사진을 생성하고 있습니다..</div>
        </div>
      )}
    </div>
  );
};

export default OptionsStep;