import React, { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import styles from './QuestionSetInfoPage2.module.css'; // CSS Modules import
import { getToken } from '../../../utils/auth';
import answerOnIcon from '../../../assets/images/questiongenerator/bulb_on.png';
import answerOffIcon from '../../../assets/images/questiongenerator/bulb_off.png';
import OnStatus from '../../../assets/images/questiongenerator/on.png';
import OffStatus from '../../../assets/images/questiongenerator/off.png';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const QuestionSetInfoPage2 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [questionsAndAnswers, setQuestionsAndAnswers] = useState([]);
  const [category, setCategory] = useState('');
  const [loading, setLoading] = useState(false);
  const [showAnswers, setShowAnswers] = useState(true); // Default to show answers
  const data = location.state;

  const categories = [
    '자기소개', '직무 관련', '기술 및 역량', '경력 및 경험',
    '지원 동기 및 회사 관련 질문', '학력 및 교육 배경', '팀워크 및 커뮤니케이션',
    '문제 해결 및 사고력', '윤리 및 가치관', '리더십 및 팀 관리'
  ];

  const handleCategoryChange = (event) => {
    setCategory(event.target.value);
  };

  const handleGenerateQuestions = async () => {
    setLoading(true);
    const token = getToken();

    try {
      const response = await fetch(`${BACKEND_URL}/tool2/generate`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({
          coverLetter: data.coverLetter,
          skills: data.skills,
          experiences: data.experiences,
          category: category,
        }),
      });

      const result = await response.json();
      if (!result.success) {
        alert(result.message);
        navigate('/main');
      }
      setQuestionsAndAnswers(result.questions[0]);
    } catch (error) {
      console.error('Error generating questions:', error);
    } finally {
      setLoading(false);
    }
  };

  const handleSaveQuestions = async () => {
    const token = getToken();

    try {
      const response = await fetch(`${BACKEND_URL}/tool2/save`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
        body: JSON.stringify({
          category: category,
          questions: questionsAndAnswers,
        }),
      });

      if (response.ok) {
        alert('질문이 성공적으로 저장되었습니다.');
        navigate('/main');
      } else {
        alert('질문 저장에 실패했습니다.');
      }
    } catch (error) {
      console.error('Error saving questions:', error);
      alert('질문 저장에 실패했습니다.');
    }
  };

  const handleBackClick = () => {
    navigate('/tools/questions-setInfo', { state: data });
  };

  const toggleAnswersVisibility = () => {
    setShowAnswers(!showAnswers);
  };

  return (
    <div className={styles.questionSetInfoPage}>
      <h2>면접 예상질문 생성기</h2>
      <p>질문 카테고리 선택 (2/2 Step)</p>
      <select value={category} onChange={handleCategoryChange}>
        <option value="">생성하고 싶은 질문 유형을 선택해주세요.</option>
        {categories.map((cat, index) => (
          <option key={index} value={cat}>{cat}</option>
        ))}
      </select>
      <div className={styles.buttonContainer}>
        <button onClick={handleBackClick} className={styles.backButton}>
          돌아가기
        </button>
        <button onClick={handleGenerateQuestions} disabled={!category || loading}>
          {loading ? '생성 중...' : '질문 생성'}
        </button>
      </div>
      {questionsAndAnswers.length > 0 && (
        <div className={styles.questionsContainer}>
          <div className={styles.toggleButtonContainer}>
            <img
              src={showAnswers ? OnStatus : OffStatus}
              alt="Toggle Answers"
              className={styles.toggleAnswersButton}
              onClick={toggleAnswersVisibility}
            />
          </div>
          {questionsAndAnswers.map((qa, index) => (
            <div key={index} className={styles.questionAnswerPair}>
              <p className={styles.question}><strong>{index + 1}. {qa.question}</strong></p>
              <div className={styles.answer}>
                <img src={showAnswers ? answerOnIcon : answerOffIcon} alt="Answer Icon" className={styles.answerIcon} />
                {showAnswers && <span>{qa.answer}</span>}
              </div>
            </div>
          ))}
          <button onClick={handleSaveQuestions}>질문 저장</button>
        </div>
      )}
    </div>
  );
};

export default QuestionSetInfoPage2;
