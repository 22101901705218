import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import ResumeEvaluationResult from './ResumeEvaluationResult';
import LoadingDots from '../../../components/LoadingDots';
import { getToken } from '../../../utils/auth';
import './ResumeEvaluationPage.css'; // CSS 파일 추가

const ResumeEvaluation = () => {
    const navigate = useNavigate();
    const [title, setTitle] = useState('');
    const [field, setField] = useState('');
    const [resumeText, setResumeText] = useState('');
    const [file, setFile] = useState(null);
    const [evaluation, setEvaluation] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(null);
    const [showResult, setShowResult] = useState(false);
    const [showTokenAlert, setShowTokenAlert] = useState(false);

    useEffect(() => {
        console.log('showTokenAlert 상태:', showTokenAlert);
    }, [showTokenAlert]);

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        setError(null);

        const formData = new FormData();
        formData.append('title', title);
        formData.append('field', field);

        if (file) {
            formData.append('resume', file);
            const reader = new FileReader();
            reader.onload = async (e) => {
                const text = e.target.result;
                formData.append('resumeText', text);
                await sendRequest(formData);
            };
            reader.onerror = (e) => {
                console.error("파일 읽기 오류:", e);
                setError("파일을 읽는 중 오류가 발생했습니다.");
                setLoading(false);
            };
            reader.readAsText(file);
        } else {
            formData.append('resumeText', resumeText);
            await sendRequest(formData);
        }
    };

    const sendRequest = async (formData) => {
        const token = getToken();

        if (!token) {
            setError('인증 토큰이 없습니다. 로그인 후 다시 시도해 주세요.');
            setLoading(false);
            return;
        }

        try {
            const plainFormData = Object.fromEntries(formData.entries());
            console.log('Sending data to server:', plainFormData);

            const response = await axios.post('http://localhost:3001/resume-evaluation/evaluate', plainFormData, {
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                }
            });

            if (response.data.success) {
                setEvaluation(response.data.evaluation);
                setShowResult(true);
            } else {
                throw new Error(response.data.error);
            }
        } catch (err) {
            console.error("API 요청 중 오류 발생:", err.response?.data || err.message);
            const errorMessage = err.response?.data?.error || err.message;
            if (errorMessage.includes('토큰이 부족합니다')) {
                console.log('토큰 부족 감지, 알림 표시 시도');
                setShowTokenAlert(true);
            } else {
                setError(errorMessage || '이력서 평가 중 오류가 발생했습니다.');
            }
        } finally {
            setLoading(false);
        }
    };

    const handleRecharge = () => {
        navigate('/payment');
    };

    const handleReset = () => {
        setTitle('');
        setField('');
        setResumeText('');
        setFile(null);
        setEvaluation(null);
        setError(null);
        setShowResult(false);
    };

    if (showResult) {
        return (
            <div className="resume-evaluation">
                <ResumeEvaluationResult evaluation={evaluation} onReset={handleReset} />
            </div>
        );
    }

    return (
        <div className="resume-evaluation">
            <div className={`form-container ${loading ? 'blurred' : ''}`}>
                <div className="form-header">
                    <h2>정보 입력 (1/1 Step)</h2>
                </div>
                <form onSubmit={handleSubmit}>
                    <div className="form-group">
                        <input
                            type="text"
                            value={title}
                            onChange={(e) => setTitle(e.target.value)}
                            placeholder="제목을 입력하세요"
                            required
                            maxLength={30}
                        />
                        <span className="char-count">{title.length}/30</span>
                    </div>
                    <div className="form-group">
                        <input
                            type="text"
                            value={field}
                            onChange={(e) => setField(e.target.value)}
                            placeholder="지원분야를 입력하세요"
                            required
                            maxLength={30}
                        />
                        <span className="char-count">{field.length}/30</span>
                    </div>
                    <div className="form-group">
                        <textarea
                            value={resumeText}
                            onChange={(e) => setResumeText(e.target.value)}
                            placeholder="여기에 이력서를 입력하세요 (파일로 제출하지 않는 경우)"
                            required={!file}
                            maxLength={1000}
                        />
                        <span className="char-count">{resumeText.length}/1000 </span>
                    </div>
                    <div className="form-group file-upload">
                        <label>이력서 (txt 또는 pdf)</label>
                        <input
                            type="file"
                            accept=".pdf,.txt"
                            onChange={handleFileChange}
                        />
                    </div>
                    <div className="form-group next-button">
                        <button type="submit" disabled={loading}>
                            {loading ? '평가 중...' : '다음'}
                        </button>
                    </div>
                </form>
                {error && <p className="error">{error}</p>}
            </div>
            {loading && (
                <div className="loading-overlay">
                    <LoadingDots text="평가 중..." />
                </div>
            )}
            {showTokenAlert && (
                <div className="token-alert-overlay">
                    <div className="token-alert">
                        <h3>토큰 부족</h3>
                        <p>이력서 평가를 위한 토큰이 부족합니다. 충전이 필요합니다.</p>
                        <div className="token-alert-buttons">
                            <button onClick={handleRecharge}>충전하러 가기</button>
                            <button onClick={() => setShowTokenAlert(false)}>닫기</button>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

export default ResumeEvaluation;
