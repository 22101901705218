import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './SavedInterviews.css';

const SavedInterviews = ({ userData }) => {
  const [interviews, setInterviews] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const mockInterviews = [
      { title: '인터뷰 1', date: '2024.07.20' },
      { title: '인터뷰 2', date: '2024.07.20' },
    ];

    setTimeout(() => {
      setInterviews(mockInterviews);
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="saved-interviews">
      <div className="header">
        <Link to="/mypage" className="back-link">
          <img src="back-icon.png" alt="Back" className="back-icon" />
          저장한 파일
        </Link>
        <h2>AI 면접 기록</h2>
      </div>
      <div className="interview-list">
        {interviews.map((interview, index) => (
          <div className="interview-item" key={index}>
            <span>{interview.title}</span>
            <span>{interview.date}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SavedInterviews;
