// categories.js
const jobs = {
    "IT/기술": [
      "소프트웨어 엔지니어", "데이터 엔지니어", "데이터 과학자", "시스템 엔지니어", "네트워크 관리자",
      "보안 엔지니어", "클라우드 엔지니어", "모바일 앱 개발자", "DevOps 엔지니어", "IT 지원 전문가",
      "시스템 관리자", "AI 엔지니어", "머신러닝 엔지니어", "게임 개발자", "웹 개발자",
      "프론트엔드 개발자", "백엔드 개발자", "데이터베이스 관리자"
    ],
    "마케팅/광고": [
      "디지털 마케팅 전문가", "광고 기획자", "브랜드 매니저", "SEO 전문가", "콘텐츠 마케팅 매니저",
      "소셜 미디어 마케터", "퍼포먼스 마케터", "마케팅 데이터 분석가", "마케팅 전략가", "PR 전문가",
      "이벤트 마케터"
    ],
    "디자인": [
      "UX/UI 디자이너", "그래픽 디자이너", "제품 디자이너", "웹 디자이너", "모션 그래픽 디자이너",
      "일러스트레이터", "크리에이티브 디렉터", "패션 디자이너", "인테리어 디자이너", "3D 모델러",
      "비주얼 아티스트"
    ],
    "경영/관리": [
      "프로젝트 매니저", "제품 매니저", "사업 개발 매니저", "경영 컨설턴트", "전략 기획자",
      "비즈니스 애널리스트", "운영 관리자", "인사 관리자", "재무 관리자", "리스크 매니저",
      "공급망 관리자"
    ],
    "교육/연구": [
      "교사", "교육 컨설턴트", "교육 프로그램 개발자", "트레이너", "교수",
      "연구원", "커리큘럼 디자이너", "교육 관리자", "온라인 교육 콘텐츠 개발자"
    ],
    "의료/보건": [
      "의사", "간호사", "약사", "물리치료사", "심리 상담사",
      "임상 연구원", "의료 기기 전문가", "응급 구조사", "방사선사", "치과 의사"
    ],
    "영업/고객 관리": [
      "영업 사원", "고객 성공 매니저", "고객 서비스 담당자", "B2B 영업 전문가", "B2C 영업 전문가",
      "판매 관리자", "CRM 관리자", "콜센터 관리자"
    ],
    "법률/법무": [
      "변호사", "법률 고문", "법률 사무원", "판사", "법률 컨설턴트",
      "검찰관", "기업 법무 담당자"
    ],
    "재무/회계": [
      "회계사", "재무 분석가", "세무사", "내부 감사관", "투자 분석가",
      "자산 관리자", "보험 전문가", "금융 컨설턴트"
    ],
    "엔지니어링": [
      "기계 엔지니어", "전기 엔지니어", "전자 엔지니어", "화학 엔지니어", "건축 엔지니어",
      "토목 엔지니어", "환경 엔지니어", "자동차 엔지니어", "항공우주 엔지니어", "로봇 공학자"
    ],
    "제조/생산": [
      "생산 관리자", "공장 관리자", "품질 관리 전문가", "생산 기술 엔지니어", "제조 공정 엔지니어",
      "공급망 관리 전문가", "유지보수 기술자"
    ],
    "예술/엔터테인먼트": [
      "배우", "음악가", "감독", "프로듀서", "시나리오 작가",
      "뮤지션", "무대 감독", "공연 연출가", "사진 작가", "영화 편집자"
    ],
    "물류/운송": [
      "물류 관리자", "운송 관리자", "물류 기획자", "창고 관리자", "배송 운전사",
      "국제 무역 전문가", "수출입 관리자", "공급망 분석가"
    ],
    "농업/환경": [
      "농업 기술자", "환경 과학자", "농업 컨설턴트", "지속 가능성 관리자", "자원 관리 전문가",
      "산림 관리자", "수산업 전문가"
    ],
    "서비스/접객": [
      "호텔 매니저", "리셉셔니스트", "고객 서비스 대표", "여행 가이드", "요리사",
      "바리스타", "이벤트 플래너", "매장 관리자"
    ],
    "미디어/저널리즘": [
      "기자", "편집자", "콘텐츠 크리에이터", "방송 프로듀서", "라디오 진행자",
      "포토그래퍼", "영상 편집자", "뉴스 앵커"
    ]
  };
  
  // jobs 객체를 기본 내보내기
  export default jobs;