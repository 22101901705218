import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { getToken } from '../../utils/auth';
import { FaArrowLeft } from 'react-icons/fa';
import './SavedResumeEvaluations.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const SavedResumeEvaluations = () => {
    const [savedEvaluations, setSavedEvaluations] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchSavedEvaluations = async () => {
            try {
                const token = getToken();
                const response = await axios.get(`${BACKEND_URL}/resume-evaluation/saved`, {
                    headers: { 'x-auth-token': token }
                });
                console.log('받아온 데이터:', response.data);
                setSavedEvaluations(response.data.evaluations || []);
            } catch (error) {
                console.error('Error fetching saved evaluations:', error);
                setError('저장된 평가를 불러오는 중 오류가 발생했습니다.');
            } finally {
                setLoading(false);
            }
        };

        fetchSavedEvaluations();
    }, []);

    const handleEvaluationClick = (evaluationId) => {
        navigate(`/resume-evaluation/${evaluationId}`);
    };

    const handleDelete = async (evaluationId, event) => {
        event.stopPropagation(); // 클릭 이벤트가 상위 요소로 전파되는 것을 막음
        const confirmDelete = window.confirm('정말로 이 이력서 평가를 삭제하시겠습니까?');
        
        if (confirmDelete) {
            try {
                const token = getToken();
                const response = await axios.delete(`${BACKEND_URL}/user/deleteResumeEval/${evaluationId}`, {
                    headers: { 'x-auth-token': token }
                });

                if (response.status === 200) {
                    alert('이력서 평가가 성공적으로 삭제되었습니다.');
                    setSavedEvaluations(savedEvaluations.filter(evaluation => evaluation._id !== evaluationId)); // 삭제된 평가를 목록에서 제거
                } else {
                    console.error('이력서 평가 삭제에 실패했습니다');
                }
            } catch (error) {
                console.error('이력서 평가 삭제 오류:', error);
            }
        }
    };

    if (loading) return <div>로딩 중...</div>;
    if (error) return <div>{error}</div>;
    if (savedEvaluations.length === 0) return <div>저장된 평가가 없습니다.</div>;

    return (
        <div className="saved-resume-evaluations">
            <div className="header">
                <Link to="/mypage" className="back-link">
                    <FaArrowLeft className="back-icon" />
                    <span className="back-text">돌아가기</span>
                </Link>
                <h2>저장된 이력서 평가</h2>
            </div>
            <div className="evaluation-list">
                {savedEvaluations.map((evaluation) => (
                    <div 
                        key={evaluation._id} 
                        className="evaluation-item"
                        onClick={() => handleEvaluationClick(evaluation._id)}
                    >
                        <div className="evaluation-info">
                            <span className="evaluation-title">{evaluation.title}</span>
                            <span className="evaluation-field">지원 분야: {evaluation.field}</span>
                        </div>
                        <span className="evaluation-date">
                            {new Date(evaluation.createdAt).toLocaleDateString()}
                        </span>
                        <button 
                            className="delete-button"
                            onClick={(event) => handleDelete(evaluation._id, event)}
                        >
                            삭제
                        </button>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SavedResumeEvaluations;