import React, { useState } from 'react';
import { CheckCircle } from 'lucide-react';
import { useNavigate } from 'react-router-dom'; // react-router-dom import
import { getToken } from '../utils/auth';
import './Payment.css'; // CSS 파일 import

const calculatePrice = (tokens, baseTokens, basePrice) => {
  const ratio = tokens / baseTokens;
  return Math.round(basePrice * ratio);
};

const PlanCard = ({ title, basePrice, baseTokens, minTokens, maxTokens, features, isActive, tokenPrice, onSubscribe }) => {
  const [tokens, setTokens] = useState(baseTokens);
  const isFree = basePrice === 0;
  
  const handleTokenChange = (e) => {
    if (!isFree) {
      const newTokens = parseInt(e.target.value);
      setTokens(newTokens);
    }
  };

  const price = isFree ? 0 : calculatePrice(tokens, baseTokens, basePrice);

  return (
    <div className={`plan-card ${isActive ? 'active' : ''}`}>
      <h3>{title}</h3>
      <p className="price">{price.toLocaleString()}원 / 월</p>
      <div className="token-slider">
        <div className="token-amount">{tokens.toLocaleString()} tokens</div>
        {!isFree && (
          <input 
            type="range" 
            min={minTokens} 
            max={maxTokens} 
            value={tokens} 
            onChange={handleTokenChange}
          />
        )}
      </div>
      <p className="token-price">
        {isFree ? '' : `약 ${tokenPrice.toFixed(2)}원 / 1,000 tokens`}
      </p>
      <ul className="features">
        {features.map((feature, index) => (
          <li key={index}>
            <CheckCircle size={16} />
            {feature}
          </li>
        ))}
      </ul>
      <button className={isActive ? 'active' : ''} onClick={() => onSubscribe(title, price)}>
        {isActive ? '이용중' : '구독하기'}
      </button>
    </div>
  );
};

const Payment = () => {
  const navigate = useNavigate();

  const plans = [
    {
      title: 'Free plan',
      basePrice: 0,
      baseTokens: 5000,
      minTokens: 5000,
      maxTokens: 5000,
      features: ['베이직 툴', '5,000 tokens'],
      isActive: true,
      tokenPrice: 0
    },
    {
      title: 'Starter plan',
      basePrice: 10000,
      baseTokens: 50000,
      minTokens: 25000,
      maxTokens: 100000,
      features: ['스타터 툴', '50,000 tokens', '커스텀 지원'],
      isActive: false,
      tokenPrice: 200  // 10,000원 / 50,000 tokens * 1000
    },
    {
      title: 'Professional plan',
      basePrice: 50000,
      baseTokens: 300000,
      minTokens: 150000,
      maxTokens: 600000,
      features: ['프로 툴', '300,000 tokens', '커스텀 지원', '전문 멘토 매칭'],
      isActive: false,
      tokenPrice: 166.67  // 50,000원 / 300,000 tokens * 1000
    }
  ];

  const handleSubscribe = (planTitle, price) => {
    const Token = getToken();
    console.log(Token);
    if (!Token) {
      alert('로그인 후 이용해주세요.');
      navigate('/login');
    }
    else{
    // 결제 정보와 함께 /payment/method로 리다이렉트
    navigate('/payment/method', { state: { planTitle, price } });
    }
  };

  return (
    <div className="payment-container">
      <h2>구독정보</h2>
      <div className="plan-cards">
        {plans.map((plan, index) => (
          <PlanCard key={index} {...plan} onSubscribe={handleSubscribe} />
        ))}
      </div>
    </div>
  );
};

export default Payment;