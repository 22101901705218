import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import SocialLoginButton from '../components/SocialLoginButton';
import { setToken } from '../utils/auth';
import { FaEnvelope, FaLock } from 'react-icons/fa';
import './LoginPage.css';

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const LoginPage = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    if (token) {
      setToken(token);
      navigate('/main');
    } else if (urlParams.get('error')) {
      alert('Google 로그인에 실패했습니다.');
    }
  }, [location, navigate]);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${BACKEND_URL}/auth/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      const data = await response.json();
      if (response.ok) {
        setToken(data.token);
        navigate('/main');
      } else {
        alert(data.msg);
      }
    } catch (error) {
      console.error('Error during login:', error);
      alert('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const handleGoogleLogin = () => {
    window.location.href = `${BACKEND_URL}/auth/google`;
  };

  return (
    <div className="login-container">
      <div className="login-page">
        <h1>Jobmaker</h1>
        <p className="login-subtitle">AI 기반 취업 플랫폼에 오신 것을 환영합니다</p>
        <form onSubmit={handleLogin}>
          <div className="input-group">
            <FaEnvelope className="input-icon" />
            <input
              type="email"
              placeholder="이메일 주소를 입력해주세요"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
          <div className="input-group">
            <FaLock className="input-icon" />
            <input
              type="password"
              placeholder="비밀번호를 입력해주세요"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
          <button type="submit">로그인</button>
        </form>
        <div className="links">
          <a href="/reset-password">비밀번호 찾기</a>
        </div>
        <div className="divider">
          <span>또는</span>
        </div>
        <div className="social-login">
          <SocialLoginButton provider="google" onClick={handleGoogleLogin} />
        </div>
        <div className="signup-link">
          아직 회원이 아니신가요? <a href="/signup">가입하기</a>
        </div>
      </div>
    </div>
  );
};

export default LoginPage;