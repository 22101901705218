import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import './SavedQuestions.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const SavedQuestions = () => {
  const [questions, setQuestions] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchQuestions = async () => {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/tool2/list`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setQuestions(data);
        } else {
          console.error('Failed to fetch interview questions');
        }
      } catch (error) {
        console.error('Error fetching interview questions:', error);
      }
    };

    fetchQuestions();
  }, []);

  const handleQuestionClick = async (questionId) => {
    try {
      const token = getToken();
      const response = await fetch(`${BACKEND_URL}/tool2/${questionId}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token,
        },
      });

      if (response.ok) {
        navigate(`/question/view/${questionId}`);
      } else {
        console.error('Failed to fetch question details');
      }
    } catch (error) {
      console.error('Error fetching question details:', error);
    }
  };

  const handleDelete = async (questionId, event) => {
    event.stopPropagation(); // 부모 요소의 클릭 이벤트 전파 방지

    const confirmDelete = window.confirm('정말로 이 면접 질문을 삭제하시겠습니까?');
    if (confirmDelete) {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/user/deleteInterviewQuestion/${questionId}`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          alert('면접 질문이 성공적으로 삭제되었습니다.');
          setQuestions(questions.filter(question => question._id !== questionId));
        } else {
          console.error('Failed to delete interview question');
        }
      } catch (error) {
        console.error('Error deleting interview question:', error);
      }
    }
  };

  return (
    <div className="saved-questions">
      <div className="header">
        <Link to="/mypage" className="back-link">
          <img src="back-icon.png" alt="Back" className="back-icon" />
          저장한 파일
        </Link>
        <h2>면접예상질문 목록</h2>
      </div>
      <div className="question-list">
        {questions.length > 0 ? (
          questions.map((question) => (
            <div
              className="question-item"
              key={question._id}
              onClick={() => handleQuestionClick(question._id)}
            >
              <span>{question.category}</span>
              <span>{new Date(question.createdDt).toLocaleDateString()}</span>
              <button className="delete-button" onClick={(event) => handleDelete(question._id, event)}>삭제</button>
            </div>
          ))
        ) : (
          <p>저장된 면접예상질문이 없습니다.</p>
        )}
      </div>
    </div>
  );
};

export default SavedQuestions;