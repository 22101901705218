import React from 'react';
import SocialLoginButton from '../components/SocialLoginButton';
import './SignupPage.css';
import { FaEnvelope } from 'react-icons/fa';

const SignupPage = () => {
  const handleGoogleLogin = () => {
    window.location.href = 'http://localhost:3001/auth/google';
  };

  return (
    <div className="signup-container">
      <div className="signup-page">
        <h1>Jobmaker</h1>
        <p className="signup-subtitle">AI 기반 취업 플랫폼에 오신 것을 환영합니다</p>
        <button className="email-signup-btn">
          <FaEnvelope className="icon" />
          <a href="/email-signup">이메일로 가입</a>
        </button>
        <div className="divider">
          <span>또는</span>
        </div>
        <div className="social-login">
          <SocialLoginButton onClick={handleGoogleLogin} />
        </div>
        <div className="login-link">
          이미 회원이신가요? <a href="/login">로그인</a>
        </div>
      </div>
    </div>
  );
};

export default SignupPage;