import React from 'react';
import './RefundTab.css';

const RefundTab = () => {
  const handleCancelSubscription = () => {
    // 구독 취소 로직을 여기에 구현합니다.
    console.log('구독 취소 요청');
  };

  return (
    <div className="refund-tab">
      <h2>환불 및 취소 정책</h2>
      <div className="refund-content">
        <section>
          <h3>1. 환불 가능 기간</h3>
          <p>구매 후 7일 이내에 환불 신청이 가능합니다.</p>
        </section>
        <section>
          <h3>2. 환불 절차</h3>
          <ol>
            <li>마이페이지에서 '환불 신청' 버튼 클릭</li>
            <li>환불 사유 선택 및 상세 내용 작성</li>
            <li>관리자 검토 후 승인 (1-3일 소요)</li>
            <li>승인 시 결제 수단으로 환불 처리</li>
          </ol>
        </section>
        <section>
          <h3>3. 환불 제한 사항</h3>
          <ul>
            <li>이미 사용한 서비스에 대해서는 환불이 불가능합니다.</li>
            <li>악용 방지를 위해 월 3회 이상 환불 시 추가 검토가 필요할 수 있습니다.</li>
          </ul>
        </section>
        <section>
          <h3>4. 문의</h3>
          <p>환불 및 취소에 대한 추가 문의사항은 고객센터로 연락 주시기 바랍니다.</p>
          <p>이메일: support@example.com</p>
          <p>전화: 02-1234-5678 (평일 9:00 - 18:00)</p>
        </section>
      </div>
      <div className="refund-actions">
        <button className="cancel-subscription-button" onClick={handleCancelSubscription}>
          구독 취소
        </button>
      </div>
    </div>
  );
};

export default RefundTab;
