import React, { useState } from 'react';
import { Outlet } from 'react-router-dom';
import Sidebar from '../components/Sidebar';
//import './MainLayout.css';

const MainLayout = () => {
  const [isOpen, setIsOpen] = useState(true);

  const toggleSidebar = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="main-layout">
      <Sidebar isOpen={isOpen} toggleSidebar={toggleSidebar} />
      <div className={`page-content ${isOpen ? 'content-open' : 'content-closed'}`}>
        <Outlet />
      </div>
    </div>
  );
};

export default MainLayout;
