// src/data/recruitData.js

const recruitData = [
    {
      id: 1,
      title: '2024년 KOTRA 통상직 신입사원 채용 공고',
      company: '코트라 KOTRA',
      location: '서울 전체',
      career: '신입/경력',
      education: '학력무관',
      postDate: '2024.09.01',
      dueDate: '2024.09.15',
      isFavorite: true,
    },
    {
      id: 2,
      title: '2024년 LG전자 마케팅직 채용 공고',
      company: 'LG전자',
      location: '경기 전체',
      career: '경력(3~5년)',
      education: '대학교졸업(4년)',
      postDate: '2024.09.03',
      dueDate: '2024.09.20',
      isFavorite: false,
    },
    {
      id: 3,
      title: '2024년 삼성전자 연구직 채용 공고',
      company: '삼성전자',
      location: '수원',
      career: '경력(1~2년)',
      education: '대학원 석사졸업',
      postDate: '2024.09.05',
      dueDate: '2024.09.22',
      isFavorite: false,
    },
    {
      id: 4,
      title: '2024년 현대자동차 설계직 신입 채용 공고',
      company: '현대자동차',
      location: '울산',
      career: '신입',
      education: '대학교졸업(4년)',
      postDate: '2024.09.10',
      dueDate: '2024.09.25',
      isFavorite: false,
    },
    {
      id: 5,
      title: '2024년 네이버 개발자 채용 공고',
      company: '네이버',
      location: '판교',
      career: '경력(2~4년)',
      education: '대학교졸업(4년)',
      postDate: '2024.09.12',
      dueDate: '2024.09.30',
      isFavorite: true,
    },
    {
      id: 6,
      title: '2024년 카카오 백엔드 개발자 채용 공고',
      company: '카카오',
      location: '제주',
      career: '경력(1~3년)',
      education: '대학교졸업(4년)',
      postDate: '2024.09.15',
      dueDate: '2024.10.01',
      isFavorite: false,
    },
    {
      id: 7,
      title: '2024년 CJ ENM 콘텐츠 제작직 신입 채용 공고',
      company: 'CJ ENM',
      location: '서울 전체',
      career: '신입',
      education: '대학교졸업(4년)',
      postDate: '2024.09.18',
      dueDate: '2024.10.05',
      isFavorite: false,
    },
    {
      id: 8,
      title: '2024년 SK하이닉스 연구개발직 채용 공고',
      company: 'SK하이닉스',
      location: '이천',
      career: '경력(3~6년)',
      education: '대학원 박사졸업',
      postDate: '2024.09.20',
      dueDate: '2024.10.10',
      isFavorite: true,
    },
    {
      id: 9,
      title: '2024년 현대모비스 생산관리직 채용 공고',
      company: '현대모비스',
      location: '울산',
      career: '경력(5~7년)',
      education: '대학교졸업(4년)',
      postDate: '2024.09.22',
      dueDate: '2024.10.15',
      isFavorite: false,
    },
    {
      id: 10,
      title: '2024년 롯데케미칼 연구직 신입 채용 공고',
      company: '롯데케미칼',
      location: '대전',
      career: '신입',
      education: '대학원 석사졸업',
      postDate: '2024.09.25',
      dueDate: '2024.10.20',
      isFavorite: false,
    },
  ];
  
  export default recruitData;