import React, { useState, useEffect } from 'react';
import './ResultStep.css'; // CSS 파일을 import

const ResultStep = ({ generatedPhotos, onBack }) => {
  const [selectedPhoto, setSelectedPhoto] = useState(null);
  const [imageErrors, setImageErrors] = useState({});

  useEffect(() => {
    console.log('generatedPhotos:', generatedPhotos);
  }, [generatedPhotos]);

  const handlePhotoSelect = (index) => {
    setSelectedPhoto(index);
  };

  const handleImageError = (index) => {
    console.error('Image load error for index:', index);
    console.error('Failed URL:', generatedPhotos[index].imageUrl);
    setImageErrors(prev => ({...prev, [index]: true}));
  };

  const handleDownload = () => {
    if (selectedPhoto !== null) {
      const link = document.createElement('a');
      link.href = generatedPhotos[selectedPhoto].imageUrl;
      link.download = `generated_photo_${selectedPhoto + 1}.png`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } else {
      alert('다운로드할 사진을 선택해주세요.');
    }
  };

  if (!generatedPhotos || generatedPhotos.length === 0) {
    return <div className="result-step__error">생성된 사진이 없습니다.</div>;
  }

  return (
    <div className="result-step">
      <h1 className="result-step__title">증명사진 생성기</h1>
      <h2 className="result-step__subtitle">결과물 선택</h2>
      <div className="result-step__generated-photos">
        {generatedPhotos.map((photo, index) => (
          <div key={index} className="result-step__generated-photo">
            {imageErrors[index] ? (
              <div className="result-step__image-error">이미지 로드 실패</div>
            ) : (
              <img 
                src={photo.imageUrl} 
                alt={`Generated ID Photo ${index + 1}`} 
                onError={() => handleImageError(index)}
              />
            )}
            <label>
              <input 
                type="radio" 
                name="selectedPhoto" 
                value={index} 
                checked={selectedPhoto === index}
                onChange={() => handlePhotoSelect(index)}
              />
              생성된 프로필 사진 - {index + 1}
            </label>
          </div>
        ))}
      </div>
      <div className="result-step__buttons">
        <button 
          className="result-step__button" 
          onClick={handleDownload}
          disabled={selectedPhoto === null}
        >
          PNG 다운로드
        </button>
        <button 
          className="result-step__button" 
          onClick={onBack}
        >
          이전 단계로 돌아가기
        </button>
      </div>
    </div>
  );
};

export default ResultStep;
