import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import './ResumeGeneratorPage1.css';
import { getToken } from '../../../utils/auth';
import jobs from './categories/job'; // job.js에서 데이터 가져오기
import fields from './categories/field'; // field.js에서 데이터 가져오기

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ResumeGeneratorPage1 = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { coverLetterId } = useParams(); // URL에서 coverLetterId 가져오기
  const [formData, setFormData] = useState({
    title: '',
    jobTitle: '',
    supportArea: '',
    companyName: '',
    experience: '',
    keywords: ''
  });

  const [experienceList, setExperienceList] = useState([]);
  const [keywordList, setKeywordList] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState(''); // 선택한 직종 카테고리
  const [selectedJob, setSelectedJob] = useState(''); // 선택한 직종
  const [selectedFieldCategory, setSelectedFieldCategory] = useState(''); // 선택한 지원 분야 카테고리
  const [selectedField, setSelectedField] = useState(''); // 선택한 지원 분야
  const [isCategoryModalOpen, setIsCategoryModalOpen] = useState(false); // 직종 카테고리 모달 상태
  const [isJobModalOpen, setIsJobModalOpen] = useState(false); // 직종 모달 상태
  const [isFieldModalOpen, setIsFieldModalOpen] = useState(false); // 지원 분야 모달 상태
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  useEffect(() => {
    // Check if coverLetter is provided via state
    if (location.state && location.state.coverLetter) {
      const { coverLetter } = location.state;
      setFormData({
        title: coverLetter.title || '',
        jobTitle: coverLetter.job || '',
        supportArea: coverLetter.field || '',
        companyName: coverLetter.company || '',
        experience: '',
        keywords: ''
      });
      setExperienceList(coverLetter.experience ? coverLetter.experience.split(', ') : []);
      setKeywordList(coverLetter.keywords ? coverLetter.keywords.split(', ') : []);
    }
  }, [location.state]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleAddExperience = () => {
    if (formData.experience.trim()) {
      setExperienceList(prevList => [...prevList, formData.experience]);
      setFormData(prevState => ({ ...prevState, experience: '' }));
    }
  };

  const handleAddKeyword = () => {
    if (formData.keywords.trim()) {
      setKeywordList(prevList => [...prevList, formData.keywords]);
      setFormData(prevState => ({ ...prevState, keywords: '' }));
    }
  };

  const handleKeyPress = (e, fieldType) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (fieldType === 'experience') {
        handleAddExperience();
      } else if (fieldType === 'keywords') {
        handleAddKeyword();
      }
    }
  };

  const handleDeleteExperience = (index) => {
    setExperienceList(prevList => prevList.filter((_, i) => i !== index));
  };

  const handleDeleteKeyword = (index) => {
    setKeywordList(prevList => prevList.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const allFieldsFilled = formData.title.trim() !== '' &&
                            formData.jobTitle.trim() !== '' &&
                            formData.supportArea.trim() !== '' &&
                            formData.companyName.trim() !== '' &&
                            experienceList.length > 0 &&
                            keywordList.length > 0;
    setIsButtonDisabled(!allFieldsFilled);
  }, [formData, experienceList, keywordList]);

  const handleSave = async (isTemporary) => {
    if (formData.title.trim() === '') {
      alert('제목을 입력해 주세요.');
      return;
    }

    try {
      const token = getToken(); // 세션 스토리지에서 JWT 토큰 가져오기
      if (!token) {
        alert("로그인이 필요한 서비스입니다.");
        navigate("/login");
      }

      const requestBody = {
        title: formData.title,
        job: formData.jobTitle,
        field: formData.supportArea,
        company: formData.companyName,
        experience: experienceList.join(', '),
        keywords: keywordList.join(', '),
        isTemporary: isTemporary
      };

      // Check if coverLetterId exists and set the appropriate URL
      let url = `${BACKEND_URL}/tool1/save`;
      if (coverLetterId) {
        url = `${BACKEND_URL}/tool1/save/${coverLetterId}`;
      }

      const response = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        },
        body: JSON.stringify(requestBody)
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const result = await response.json();

      if (isTemporary) {
        alert('임시저장이 완료되었습니다.');
        navigate('/main');
      } else {
        const newCoverLetterId = result.coverLetterId;
        navigate(`/tools/resume-generator/generate/${newCoverLetterId}`);
      }
    } catch (error) {
      console.error('Error saving cover letter info:', error.message);
    }
  };

  // 모달 열기/닫기 핸들러
  const openCategoryModal = () => setIsCategoryModalOpen(true);
  const closeCategoryModal = () => setIsCategoryModalOpen(false);

  const openJobModal = () => setIsJobModalOpen(true);
  const closeJobModal = () => setIsJobModalOpen(false);

  const openFieldModal = () => setIsFieldModalOpen(true);
  const closeFieldModal = () => setIsFieldModalOpen(false);

  // 카테고리 선택 핸들러
  const handleCategorySelect = (category) => {
    setSelectedCategory(category);
    setSelectedJob('');
    closeCategoryModal(); // 카테고리 선택 후 모달 닫기
    openJobModal(); // 직종 모달 열기
  };

  // 직종 선택 핸들러
  const handleJobSelect = (job) => {
    setSelectedJob(job);
    setFormData(prevState => ({ ...prevState, jobTitle: job })); // 직종을 formData에 반영
    closeJobModal(); // 직종 선택 후 모달 닫기

    // 직종 카테고리에 따라 지원 분야 카테고리 자동 선택
    setSelectedFieldCategory(selectedCategory);
    setSelectedField(''); // 이전 선택된 지원 분야 초기화
  };

  // 지원 분야 선택 핸들러
  const handleFieldSelect = (field) => {
    setSelectedField(field);
    setFormData(prevState => ({ ...prevState, supportArea: field })); // 지원 분야를 formData에 반영
    closeFieldModal(); // 지원 분야 선택 후 모달 닫기
  };

  return (
    <div className="resume-generator">
      <h2>자소서생성기</h2>
      <p>정보 입력 (1/2 Step)</p>
      <div className="form-group">
        <label>
          제목
          <input
            type="text"
            name="title"
            value={formData.title}
            onChange={handleChange}
            placeholder="예시) 언제나 사용자와 입장에서 생각하는 디자이너, 홍길동입니다."
            maxLength="30"
          />
        </label>

        {/* 직종 선택 버튼 */}
        <label>
          직종 선택<br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              name="jobTitle"
              value={formData.jobTitle}
              onChange={handleChange}
              placeholder="직종 선택"
              maxLength="30"
              style={{ width: '50%' }} // input 길이 조정
            />
            <button type="button" onClick={openCategoryModal} style={{ marginLeft: '10px' }}>
              선택하기
            </button>
          </div>
        </label>
        
        {/* 지원 분야 선택 버튼 */}
        <label>
          지원 분야 선택<br />
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <input
              type="text"
              name="supportArea"
              value={formData.supportArea}
              onChange={handleChange}
              placeholder="지원 분야 선택"
              maxLength="30"
              style={{ width: '50%' }} // input 길이 조정
            />
            <button type="button" onClick={openFieldModal} style={{ marginLeft: '10px' }}>
              선택하기
            </button>
          </div>
        </label>

        {/* 기타 입력 필드 */}
        <label>
          기업명
          <input
            type="text"
            name="companyName"
            value={formData.companyName}
            onChange={handleChange}
            placeholder="예시) 삼성전자"
            maxLength="30"
          />
        </label>

        {/* 경력 기술 및 키워드 섹션 */}
        <div className="experience-section">
          <label>
            경력 기술<br></br>
            <input
              type="text"
              name="experience"
              value={formData.experience}
              onChange={handleChange}
              onKeyPress={(e) => handleKeyPress(e, 'experience')}
              placeholder="예시)[디자인 툴] 사용 2년"
              maxLength="30"
            />
            <button type="button" onClick={handleAddExperience}>+</button>
          </label>
        </div>
        <div className="experience-list">
          {experienceList.map((exp, index) => (
            <span key={index} className="tag">
              {exp} <button type="button" onClick={() => handleDeleteExperience(index)}>x</button>
            </span>
          ))}
        </div>

        <div className="keyword-section">
          <label>
            키워드<br></br>
            <input
              type="text"
              name="keywords"
              value={formData.keywords}
              onChange={handleChange}
              onKeyPress={(e) => handleKeyPress(e, 'keywords')}
              placeholder="예시) 열정"
              maxLength="30"
            />
            <button type="button" onClick={handleAddKeyword}>+</button>
          </label>
        </div>
        <div className="keyword-list">
          {keywordList.map((keyword, index) => (
            <span key={index} className="tag">
              {keyword} <button type="button" onClick={() => handleDeleteKeyword(index)}>x</button>
            </span>
          ))}
        </div>

        {/* Action 버튼 */}
        <div className="action-buttons">
          <button disabled={isButtonDisabled} onClick={() => handleSave(false)}>다음</button>
          <button onClick={() => handleSave(true)}>임시저장</button>
        </div>
      </div>

      {/* 직종 카테고리 모달 */}
      {isCategoryModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>직종 카테고리 선택</h3>
            <button onClick={closeCategoryModal}>닫기</button>
            {Object.keys(jobs).map((category) => (
              <div key={category}>
                <button onClick={() => handleCategorySelect(category)}>{category}</button>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* 직종 모달 */}
      {isJobModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>직종 선택 ({selectedCategory})</h3>
            <button onClick={closeJobModal}>닫기</button>
            {jobs[selectedCategory] && jobs[selectedCategory].map((job) => ( // jobs[selectedCategory]가 undefined가 아닐 때만 map 실행
              <div key={job}>
                <button onClick={() => handleJobSelect(job)}>{job}</button>
              </div>
            ))}
          </div>
        </div>
      )}

      {/* 지원 분야 모달 */}
      {isFieldModalOpen && (
        <div className="modal">
          <div className="modal-content">
            <h3>지원 분야 선택 ({selectedFieldCategory})</h3>
            <button onClick={closeFieldModal}>닫기</button>
            {fields[selectedFieldCategory] && fields[selectedFieldCategory].map((field) => ( // fields[selectedFieldCategory]가 undefined가 아닐 때만 map 실행
              <div key={field}>
                <button onClick={() => handleFieldSelect(field)}>{field}</button>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};

export default ResumeGeneratorPage1;