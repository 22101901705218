import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import MainPage from './pages/MainPage';
import LandingPage from './pages/LandingPage';
//import InfosPage from './pages/InfosPage';
import CommunityPage from './pages/community/CommunityPage';

import MyPage from './pages/MyPage';
import LoginPage from './pages/LoginPage';
import ResetPasswordPage from './pages/ResetPasswordPage';  // 비밀번호 찾기 페이지
import SignupPage from './pages/SignupPage';
import EmailSignupPage from './pages/EmailSignupPage';
import CompleteProfilePage from './pages/CompleteProfilePage';

// Resume pages
import ResumeGeneratorPage1 from './pages/tools/resume/ResumeGeneratorPage1';  // 자소서 생성기 step1 페이지
import ResumeGeneratorPage2 from './pages/tools/resume/ResumeGeneratorPage2';  // 자소서 생성기 step2 페이지
import ResumeViewPage from './pages/tools/resume/ResumeViewPage';  // 자소서 저장 후 view 페이지

// Photo pages
import PhotoGeneratorPage from './pages/tools/photos/PhotoGeneratorPage';

// Questions pages
import QuestionSetInfoPage1 from './pages/tools/questions/QuestionSetInfoPage1';  // 면접 예상 질문 생성 step1 페이지
import QuestionSetInfoPage2 from './pages/tools/questions/QuestionSetInfoPage2';  // 면접 예상 질문 생성 step1 페이지
import QuestionViewPage from './pages/tools/questions/QuestionViewPage';  // 면접 예상 질문 view 페이지

// TechTree Pages
import TechTreeGeneratorPage from './pages/tools/techtree/TechTreeGeneratorPage';
import TechTreeDetail from './pages/tools/techtree/TechTreeDetail';  // 새로 추가

import JobMatchingPage from './pages/tools/JobMatchingPage';
import AIInterviewerPage from './pages/tools/AIInterviewerPage';
import ResumeEvaluationPage from './pages/tools/resumeevaluation/ResumeEvaluationPage'; // 새로 추가

// admin
import AdminPage from './pages/admin/AdminPage';

// recruit information pages
import RecruitInfoPage from './pages/recruit/RecruitInfoPage';

import GoogleCallback from './pages/GoogleCallback';
import MainLayout from './layouts/MainLayout';
import ProtectedRoute from './components/ProtectedRoute';
import Payment from './pages/Payment';
import './App.css';

// 자소서 평가 저장 페이지
import SavedResumeEvaluation from './components/saved/SavedResumeEvaluations';
// 자소서 평가 상세 페이지
import ResumeEvaluationDetail from './pages/tools/resumeevaluation/ResumeEvaluationDetail';
// 커뮤니티 게시글 상세 페이지
import PostDetail from './pages/community/PostDetail';
import WritePost from './pages/community/WritePost';
import EditPost from './pages/community/EditPost'; // 새로 추가

function App() {
  return (
    <Router>
      <div className="App">
        <Routes>
          <Route path="/" element={<LandingPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/reset-password" element={<ResetPasswordPage />} />
          <Route path="/auth/google/callback" element={<GoogleCallback />} />
          <Route path="/signup" element={<SignupPage />} />
          <Route path="/email-signup" element={<EmailSignupPage />} />
          <Route path="/complete-profile" element={<CompleteProfilePage />} /> {/* CompleteProfilePage 추가 */}
          <Route path="/admin" element={<AdminPage />} /> {/* 관리자페이지 테스트용 */}
          <Route element={<MainLayout />}>
            <Route path="/recruit/info" element={<ProtectedRoute element={RecruitInfoPage} />} />
            <Route path="/main" element={< MainPage />} />
            <Route path="/community" element={<CommunityPage />} />
            <Route path="/payment" element={<Payment />} />
            <Route path="/mypage" element={<ProtectedRoute element={MyPage} />} />
            <Route path="/tools/resume-generator/setinfo" element={<ResumeGeneratorPage1 />} />
            <Route path="/tools/resume-generator/setinfo/:coverLetterId" element={<ResumeGeneratorPage1 />} />
            <Route path="/tools/resume-generator/generate/:coverLetterId" element={<ProtectedRoute element={ResumeGeneratorPage2} />} />
            <Route path="/coverletter/view/:coverLetterId" element={<ProtectedRoute element={ResumeViewPage} />} />
            <Route path="/question/view/:questionId" element={<ProtectedRoute element={QuestionViewPage} />} />
            <Route path="/mypage/*" element={<ProtectedRoute element={MyPage} />} />
            <Route path="/tools/photo-generator" element={< PhotoGeneratorPage />} />
            <Route path="/tools/questions-setInfo" element={< QuestionSetInfoPage1 />} />
            <Route path="/tools/questions-setCategory" element={< ProtectedRoute element={QuestionSetInfoPage2} />} />
            <Route path="/tools/tech-tree-generator" element={< ProtectedRoute element={TechTreeGeneratorPage} />} />
            <Route path="/tools/tech-tree/:techTreeId" element={< ProtectedRoute element={TechTreeDetail} />} />
            <Route path="/tools/job-matching" element={< JobMatchingPage />} />
            <Route path="/tools/ai-interviewer" element={< AIInterviewerPage />} />
            <Route path="/tools/resume-evaluation" element={<ProtectedRoute element={ResumeEvaluationPage} />} /> 
            <Route path="/saved-evaluations" component={SavedResumeEvaluation} />
            <Route path="/resume-evaluation/:id" element={<ResumeEvaluationDetail />} />
            
            <Route path="/community" element={<ProtectedRoute element={CommunityPage} />} />
            <Route path="/community/post/:postId" element={<ProtectedRoute element={PostDetail} />} />
            <Route path="/community/writePost" element={<ProtectedRoute element={WritePost} />} />
            <Route path="/community/edit/:postId" element={<ProtectedRoute element={EditPost} />} /> {/* 새로 추가 */}
          </Route>
        </Routes>
      </div>
    </Router>
  );
}

export default App;