import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import TechTreeForm from './TechTreeForm';
import TechTreeResult from './TechTreeResult';
import { getToken } from '../../../utils/auth';     // JWT 토큰 불러오기
import styles from './TechTreeGeneratorPage.module.css';  // 통합된 CSS 모듈 import

const TechTreeGeneratorPage = () => {
    const [recommendations, setRecommendations] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');
    const navigate = useNavigate();

    const handleFormSubmit = async ({ jobTitle, role, responsibilities }) => {
        setLoading(true);
        setError('');
        const token = getToken();

        try {
            const response = await axios.post(
                'http://localhost:3001/techtree/generate',
                { jobTitle, role, responsibilities },
                { headers: { 'x-auth-token': token } }
            );

            if (response.data.success) {
                setRecommendations({ ...response.data.recommendations, jobTitle, role, responsibilities });
            } else {
                setPopupMessage(response.data.message);
                setShowPopup(true);
            }
        } catch (err) {
            setError('추천 내용을 불러오는 데 실패했습니다. 다시 시도해 주세요.');
        } finally {
            setLoading(false);
        }
    };

    const handleCopy = () => {
        const { jobTitle, role, responsibilities, programs, certifications, skills } = recommendations;
        const copyText = `
        직종: ${jobTitle}
        직무: ${role}
        담당업무: ${responsibilities}
        
        추천 프로그램: ${programs.map(p => p.name).join(', ')}
        추천 자격증: ${certifications.map(c => c.name).join(', ')}
        추천 역량: ${skills.map(s => s.name).join(', ')}
        `;
        navigator.clipboard.writeText(copyText)
            .then(() => {
                setPopupMessage('복사되었습니다!');
                setShowPopup(true);
                setTimeout(() => setShowPopup(false), 2000);
            })
            .catch(() => setError('복사에 실패했습니다.'));
    };

    const handleSave = async () => {
        try {
            const token = getToken();
            const response = await axios.post(
                'http://localhost:3001/techtree/save',
                recommendations,
                { headers: { 'x-auth-token': token } }
            );
            if (response.data.success) {
                setPopupMessage('저장되었습니다!');
                setShowPopup(true);
                setTimeout(() => setShowPopup(false), 2000);
            } else {
                setError(response.data.error || '저장에 실패했습니다.');
            }
        } catch (err) {
            console.error('저장 중 오류 발생:', err);
            setError('저장에 실패했습니다. 다시 시도해 주세요.');
        }
    };

    const handlePaymentRedirect = () => {
        navigate('/payment');
    };

    return (
        <div className={styles.techTreeContainer}>
            {!recommendations ? (
                <TechTreeForm onSubmit={handleFormSubmit} />
            ) : (
                <TechTreeResult
                    recommendations={recommendations}
                    jobTitle={recommendations.jobTitle}
                    role={recommendations.role}
                    responsibilities={recommendations.responsibilities}
                    onCopy={handleCopy}
                    onSave={handleSave}
                />
            )}

            {loading && <p className={styles.loadingMessage}>생성 중...</p>}
            {error && <p className={styles.errorMessage}>{error}</p>}

            {showPopup && (
                <div className={styles.popup}>
                    <p>{popupMessage}</p>
                    <div className={styles.popupButtons}>
                        <button onClick={() => setShowPopup(false)}>확인</button>
                        {popupMessage.includes('토큰이 부족합니다') && (
                            <button onClick={handlePaymentRedirect} className={styles.chargeButton}>충전하기</button>
                        )}
                    </div>
                </div>
            )}
        </div>
    );
};

export default TechTreeGeneratorPage;
