import React, { useState } from 'react';
import styles from './TechTreeGeneratorPage.module.css';  // 통합된 CSS 모듈 import

const TechTreeForm = ({ onSubmit }) => {
    const [jobTitle, setJobTitle] = useState('');
    const [role, setRole] = useState('');
    const [responsibilities, setResponsibilities] = useState('');

    const maxLength = 30;

    const isFormComplete = jobTitle && role && responsibilities;

    const handleSubmit = (e) => {
        e.preventDefault();
        if (isFormComplete) {
            onSubmit({ jobTitle, role, responsibilities });
        }
    };

    const handleInputChange = (setter) => (e) => {
        const value = e.target.value.slice(0, maxLength);
        setter(value);
    };

    const renderInput = (value, setter, label, placeholder) => (
        <div className={styles.formGroup}>
            <label>{label}:</label>
            <div className="input-container">
                <input
                    type="text"
                    value={value}
                    onChange={handleInputChange(setter)}
                    placeholder={placeholder}
                    required
                    maxLength={maxLength}
                />
                <span className={styles.charCount}>{value.length}/{maxLength}</span>
            </div>
        </div>
    );

    return (
        <div className={styles.techTreeContainer}>
            <h2 className={styles.heading}>테크트리 생성기</h2> {/* 구별된 스타일 적용 */}
            <form onSubmit={handleSubmit}>
                {renderInput(jobTitle, setJobTitle, "직종", "예: 시각디자인")}
                {renderInput(role, setRole, "직무", "예: UI디자이너")}
                {renderInput(responsibilities, setResponsibilities, "담당업무", "예: 모바일 UI디자인")}
                <button type="submit" disabled={!isFormComplete} className={styles.submitBtn}>
                    생성
                </button>
            </form>
        </div>
    );
};

export default TechTreeForm;
