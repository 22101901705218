import React, { useEffect, useState } from 'react';
import { Route, Routes, Link, useNavigate } from 'react-router-dom';
import './MyPage.css';
import SavedResumes from '../components/saved/SavedResumes';
import SavedPhotos from '../components/saved/SavedPhotos';
import SavedQuestions from '../components/saved/SavedQuestions';
import SavedInterviews from '../components/saved/SavedInterviews';
import SavedTechTrees from '../components/saved/SavedTechTrees';
import ProfileTab from '../components/Tabs/ProfileTab';
import RefundTab from '../components/Tabs/RefundTab';
import AccountTab from '../components/Tabs/AccountTab';
import PrivacyTab from '../components/Tabs/PrivacyTab';
import { getToken } from '../utils/auth';
import SavedResumeEvaluations from '../components/saved/SavedResumeEvaluations';

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const MyPage = () => {
  const [userData, setUserData] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUserData(data);
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    if (!window.location.pathname.includes('saved-') && 
        !window.location.pathname.includes('refund') && 
        !window.location.pathname.includes('account') && 
        !window.location.pathname.includes('privacy')) {
      navigate('profile');
    }
  }, [navigate]);

  if (!userData) {
    return <div>Loading...</div>;
  }

  return (
    <div className="my-page">
      <div className="tabs">
        <Link to="/mypage/profile">내 정보</Link>
        <Link to="/mypage/refund">환불/취소</Link>
        <Link to="/mypage/account">회원정보관리</Link>
        <Link to="/mypage/privacy">개인정보 처리방침</Link>
      </div>
      <Routes>
        <Route path="profile" element={<ProfileTab userData={userData} />} />
        <Route path="refund" element={<RefundTab />} />
        <Route path="account" element={<AccountTab />} />
        <Route path="privacy" element={<PrivacyTab />} />
        <Route path="saved-resumes" element={<SavedResumes />} />
        <Route path="saved-photos" element={<SavedPhotos />} />
        <Route path="saved-questions" element={<SavedQuestions />} />
        <Route path="saved-interviews" element={<SavedInterviews />} />
        <Route path="saved-tech-trees" element={<SavedTechTrees />} />
        <Route path="saved-resume-evaluations" element={<SavedResumeEvaluations />} />
      </Routes>
    </div>
  );
};

export default MyPage;