import React, { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { setToken } from '../utils/auth';

const GoogleCallback = () => {
  const navigate = useNavigate();
  const location = useLocation();

  useEffect(() => {
    const urlParams = new URLSearchParams(location.search);
    const token = urlParams.get('token');

    if (token) {
      setToken(token);
      navigate('/main');
    } else {
      alert('Google 로그인에 실패했습니다.');
      navigate('/login');
    }
  }, [location, navigate]);

  return <div>Loading...</div>;
};

export default GoogleCallback;