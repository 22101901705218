import React, { useState } from 'react';
import './QuestionResponse.css';

const QuestionResponse = ({
  index,
  question,
  response,
  isCustom,
  isLoading,
  onQuestionChange,
  onCustomQuestionChange,
  onGenerateDraft,
  onRegenerateDraft,
  onDelete,
  onResponseChange,
  predefinedQuestions
}) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // State to manage dropdown visibility

  // Handle dropdown toggle
  const toggleDropdown = () => {
    setIsDropdownOpen((prev) => !prev);
  };

  // Handle question selection from the dropdown
  const handleSelectQuestion = (selectedQuestion) => {
    onQuestionChange(index, { target: { value: selectedQuestion } });
    setIsDropdownOpen(false); // Close dropdown after selecting a question
  };

  return (
      <div className="question-response-block">
        <div className="question-number">{index + 1}</div>
            {/* Right-side item (delete button) */}
            <button className="QuestionResponse-delete-button" onClick={() => onDelete(index)}>
              삭제하기
            </button>
          <div className="question-left-item">
            {/* <label className="input-label"> */}
              <input
                className="question-text"
                type="text"
                value={question}
                onChange={(e) => onCustomQuestionChange(index, e)}
                placeholder="직접 입력 또는 가이드 질문 선택"
                maxLength="50"
              />
              <button className="question-dropdown-button" onClick={toggleDropdown}>
                {isDropdownOpen ? '▲' : '▼'}
              </button>
              {isDropdownOpen && (
                <ul className="question-dropdown-list">
                  {predefinedQuestions.map((q, i) => (
                    <li key={i} onClick={() => handleSelectQuestion(q)}>
                      {q}
                    </li>
                  ))}
                </ul>
              )}
            {/* </label> */}
          </div>
        {/* Generate button container */}
        <div className="generate-button-container">
          <button
            className="generate-button"
            onClick={() => onGenerateDraft(index)}
            disabled={isLoading}
          >
            {isLoading ? '본문을 생성하고 있습니다..' : '초안 생성'}
          </button>
        </div>

        {response && (
          <div className="response-block">
            <textarea
              className="response-textarea"
              value={response}
              onChange={(e) => onResponseChange(index, e)}
            />
            <button
              className="questionresponse-regenerate-button"
              onClick={() => onRegenerateDraft(index)}
              disabled={isLoading}
            >
              재생성
            </button>
          </div>
        )}
      </div>
  );
};

export default QuestionResponse;