import React, { useEffect, useState } from 'react';
import { useParams, Link } from 'react-router-dom';
import { getToken } from '../../../utils/auth';
import styles from './TechTreeDetail.module.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const TechTreeDetail = () => {
  const [techTree, setTechTree] = useState(null);
  const [loading, setLoading] = useState(true);
  const { techTreeId } = useParams();

  useEffect(() => {
    const fetchTechTree = async () => {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/user/tech-trees/${techTreeId}`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setTechTree(data);
        } else {
          console.error('테크트리 데이터를 가져오는데 실패했습니다');
        }
      } catch (error) {
        console.error('테크트리 데이터 가져오기 오류:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchTechTree();
  }, [techTreeId]);

  if (loading) {
    return <div>로딩 중...</div>;
  }

  if (!techTree) {
    return <div>테크트리를 찾을 수 없습니다.</div>;
  }

  return (
    <div className={styles.techTreeDetail}>
      <h2 className={styles.title}>{techTree.jobTitle} - {techTree.role}</h2>
      <p className={styles.responsibilities}>담당업무: {techTree.responsibilities}</p>
      
      <h3 className={styles.sectionTitle}>프로그램</h3>
      <ul className={styles.list}>
        {techTree.programs.map((program, index) => (
          <li key={index} className={styles.listItem}>
            <span className={styles.itemName}>{program.name}</span>
            <p className={styles.itemDescription}>{program.description}</p>
          </li>
        ))}
      </ul>
      
      <h3 className={styles.sectionTitle}>자격증</h3>
      <ul className={styles.list}>
        {techTree.certifications.map((cert, index) => (
          <li key={index} className={styles.listItem}>
            <span className={styles.itemName}>{cert.name} ({cert.issuing_organization})</span>
            <p className={styles.itemDescription}>{cert.description}</p>
          </li>
        ))}
      </ul>
      
      <h3 className={styles.sectionTitle}>기술</h3>
      <ul className={styles.list}>
        {techTree.skills.map((skill, index) => (
          <li key={index} className={styles.listItem}>
            <span className={styles.itemName}>{skill.name}</span>
            <p className={styles.itemDescription}>{skill.description}</p>
          </li>
        ))}
      </ul>
      
      <Link to="/mypage/saved-tech-trees" className={styles.backLink}>뒤로 가기</Link>
    </div>
  );
};

export default TechTreeDetail;