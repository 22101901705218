// ProtectedRoute.jsx
import React from 'react';
import { Navigate } from 'react-router-dom';
import { getToken } from '../utils/auth';

const ProtectedRoute = ({ element: Component }) => {
  const token = getToken();

  if (!token) {
    alert('로그인이 필요한 서비스입니다.');
    return <Navigate to="/login" />;
  }

  return <Component />;
};

export default ProtectedRoute;