import React, { useEffect, useState } from 'react';
import UserDetailModal from '../components/UserDetailModal'; // 모달 컴포넌트 import
import { getToken } from '../utils/auth'; // 유저의 토큰 가져오기
import axios from 'axios';
import { Link } from 'react-router-dom';
import './MainPage.css';

// 아이콘 이미지 import (실제 경로에 맞게 수정 필요)
import icon1 from '../assets/images/icons/icon1.png';
import icon2 from '../assets/images/icons/icon2.png';
import icon3 from '../assets/images/icons/icon3.png';
import icon4 from '../assets/images/icons/icon4.png';
import icon5 from '../assets/images/icons/icon5.png';
// import icon6 from '../assets/images/icons/icon6.png'; // 아직 준비되지 않음

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const tools = [
  { title: "자소서 작성", image: icon1, link: "/tools/resume-generator/setinfo", badge: { text: "HOT", type: "hot" } },
  { title: "증명사진 생성", image: icon2, link: "/tools/photo-generator", badge: { text: "HOT", type: "hot" } },
  { title: "자소서/이력서 테스트", image: icon3, link: "/tools/resume-evaluation" },
  { title: "예상 면접 질문", image: icon4, link: "/tools/questions-setInfo", badge: { text: "인기", type: "popular" } },
  { title: "필요한 스펙 분석", image: icon5, link: "/tools/tech-tree-generator" },
  { title: "잡 매칭", image: icon1, link: "/tools/job-matching" },
  { title: "AI 면접", image: icon2, link: "/tools/ai-interviewer" },
];

const MainPage = () => {
  const [showModal, setShowModal] = useState(false); // 모달 표시 상태

  useEffect(() => {
    // 사용자 정보 확인 함수
    const fetchUserInfo = async () => {
      const token = getToken(); // 토큰 가져오기
      if (!token) {
        // 토큰이 없는 경우 함수 종료
        return;
      }

      try {
        // 유저 정보 API 호출
        const response = await axios.get(`${BACKEND_URL}/user`, {
          headers: { 'x-auth-token': token },
        });

        // userDetail이 존재하는지 확인
        if (!response.data.userDetail) {
          setShowModal(true); // userDetail이 없는 경우 모달 표시
        }
      } catch (error) {
        console.error('Error fetching user info:', error);
      }
    };

    fetchUserInfo();
  }, []);

  return (
    <div className="main-page">
      <h1 className="main-title">AI들이 도와주는 취업, 잡메이커</h1>
      <div className="tools-container">
        {tools.map((tool, index) => (
          <Link key={index} to={tool.link} className="tool-item">
            <div className="tool-icon">
              {tool.badge && <span className={`tool-badge ${tool.badge.type}`}>{tool.badge.text}</span>}
              <img src={tool.image} alt={tool.title} />
            </div>
            <p>{tool.title}</p>
          </Link>
        ))}
      </div>
      {showModal && <UserDetailModal />} {/* 세부 정보가 없는 경우 모달 표시 */}
      <div className="info-container">
        <div className="info-box news">
          <h3>취업 뉴스</h3>
          <ol>
            <li>이 편지는 영국에서부터 시작되어</li>
            <li>이 편지는 영국에서부터 시작되어</li>
            <li>이 편지는 영국에서부터 시작되어</li>
          </ol>
        </div>
        <div className="info-box community">
          <h3>커뮤니티 인기 게시글</h3>
          <p>이거 제 정장인가요?</p>
          <p>이 편지는 영국에서부터 시작되어</p>
        </div>
        <div className="info-box job-posting">
          <h3>지금 인기 있는 공고</h3>
          <p>GS건설</p>
          <p>2024년 하반기 신입사원 채용 ~09.29</p>
        </div>
      </div>
    </div>
  );
};

export default MainPage;