import React, { useState } from 'react';
import axios from 'axios';
import { getToken } from '../../utils/auth';

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const AdminPage = () => {
    const [users, setUsers] = useState([]);
    const [error, setError] = useState(null);

    const fetchUsers = async () => {
        try {
            // 로그인한 유저 token 가져오기
            const token = getToken();

            // 백엔드로 요청 보내기
            const response = await axios.get(`${BACKEND_URL}/admin/users`, {
                headers: {
                    'x-auth-token': token
                }
            });

            // 응답에서 유저 목록 설정
            setUsers(response.data);
            setError(null); // 오류 초기화
        } catch (err) {
            // 백엔드에서 전달된 오류 메시지를 그대로 표시
            const errorMessage = err.response?.data?.msg || '유저 목록을 가져오는 데 실패했습니다.';
            setError(errorMessage);
            console.error('Error:', err.response || err.message);
        }
    };

    return (
        <div>
            <h1>관리자 페이지</h1>
            <button onClick={fetchUsers}>목록 가져오기</button>

            {error && <p style={{ color: 'red' }}>{error}</p>}

            {users.length > 0 ? (
                <table>
                    <thead>
                        <tr>
                            <th>이메일</th>
                            <th>사용자 이름</th>
                            <th>역할</th>
                            <th>가입일</th>
                        </tr>
                    </thead>
                    <tbody>
                        {users.map((user) => (
                            <tr key={user._id}>
                                <td>{user.email}</td>
                                <td>{user.username}</td>
                                <td>{user.role}</td>
                                <td>{new Date(user.createdDt).toLocaleDateString()}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            ) : (
                <p>유저 목록이 없습니다.</p>
            )}
        </div>
    );
};

export default AdminPage;