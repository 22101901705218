import React, { useState } from 'react';
import PhotoUploadStep from './PhotoUploadStep';
import OptionsStep from './OptionsStep';
import ResultStep from './ResultStep';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import './PhotoGeneratorPage.css';

const PhotoGeneratorPage = () => {
  const [step, setStep] = useState(1);
  const [mainPhoto, setMainPhoto] = useState(null);
  const [gender, setGender] = useState('');
  const [style, setStyle] = useState('');
  const [generatedPhotos, setGeneratedPhotos] = useState([]);
  const [loading, setLoading] = useState(false);

  const handleNextStep = () => {
    setStep(step + 1);
  };

  const handlePrevStep = () => {
    setStep(step - 1);
  };

  const handleGeneratePhotos = async () => {
    const token = getToken();
    setLoading(true);
    const formData = new FormData();
    
    // 이 부분을 확인하세요
    if (!mainPhoto) {
      alert('이미지를 선택해주세요.');
      setLoading(false);
      return;
    }
    
    formData.append('faceImage', mainPhoto);
    formData.append('gender', gender);
    formData.append('style', style);

    console.log('Sending data:', { mainPhoto, gender, style }); // 디버깅용

    try {
      const response = await axios.post('http://localhost:3001/photos/generate', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'x-auth-token': token,
        },
      });
      console.log('Server response:', response.data);
      // setGeneratedPhotos(response.data.photos);  // 이 줄을 아래와 같이 수정
      setGeneratedPhotos([{ imageUrl: response.data.imageUrl }]);
      handleNextStep();
    } catch (error) {
      console.error('Error generating photos:', error);
      if (error.response) {
        console.error('Response data:', error.response.data);
        console.error('Response status:', error.response.status);
      }
      alert('사진 생성 중 오류가 발생했습니다: ' + (error.response?.data?.message || error.message));
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="photo-generator-container">
      {step === 1 && (
        <PhotoUploadStep
          mainPhoto={mainPhoto}
          setMainPhoto={setMainPhoto}
          handleNextStep={handleNextStep}
        />
      )}
      {step === 2 && (
        <OptionsStep
          gender={gender}
          setGender={setGender}
          style={style}
          setStyle={setStyle}
          handleGeneratePhotos={handleGeneratePhotos}
          handlePrevStep={handlePrevStep}
          loading={loading}
        />
      )}
      {step === 3 && (
        <ResultStep
          generatedPhotos={generatedPhotos}
          onBack={handlePrevStep} // 이전 단계로 돌아가는 함수 전달
        />
      )}
    </div>
  );
};

export default PhotoGeneratorPage;
