import React, { useState } from 'react';
import axios from 'axios';
import { getToken } from '../utils/auth';
import './UserDetailModal.css';

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const UserDetailModal = () => {
  const [step, setStep] = useState(1); // 현재 단계 상태
  const [selectedField, setSelectedField] = useState(''); // 선택된 분야 상태
  const [selectedExperience, setSelectedExperience] = useState(''); // 선택된 경력 상태
  const [loading, setLoading] = useState(false); // 로딩 상태
  const [error, setError] = useState(''); // 오류 상태
  const [successMessage, setSuccessMessage] = useState(''); // 성공 메시지

  // 분야 선택 시 호출되는 함수
  const handleFieldSelect = (field) => {
    setSelectedField(field);
    setError(''); // 오류 메시지 초기화
  };

  // 경력 선택 시 호출되는 함수
  const handleExperienceSelect = (experience) => {
    setSelectedExperience(experience);
    setError(''); // 오류 메시지 초기화
  };

  // 다음 단계로 이동하는 함수
  const goToNextStep = () => {
    if (step === 1) {
      // 1단계는 무조건 다음 단계로 이동
      setStep(2);
      return;
    }
    if (step === 2 && !selectedField) {
      setError('분야를 선택해주세요.');
      return;
    }
    if (step === 3 && !selectedExperience) {
      setError('경력을 선택해주세요.');
      return;
    }
    setStep((prevStep) => prevStep + 1);
  };

  // 끝내기 버튼 클릭 시 호출되는 함수
  const handleSubmit = async () => {
    if (!selectedField || !selectedExperience) {
      alert('모든 정보를 선택해야 합니다.');
      return;
    }
    setLoading(true);
    setError('');
    try {
      const token = getToken();
      if (!token) {
        setError('로그인이 필요합니다.');
        return;
      }

      // 서버로 선택한 정보 전송
      await axios.post(
        `${BACKEND_URL}/user/detail`,
        {
          major: selectedField, // 선택한 분야를 major로 전송
          experience: selectedExperience, // 선택한 경력을 experience로 전송
        },
        { headers: { 'x-auth-token': token } }
      );

      setSuccessMessage('정보가 성공적으로 저장되었습니다!');
      // 모달 창 닫기 로직 추가
      setTimeout(() => {
        window.location.reload(); // 페이지 리로드하여 모달 창을 닫음
      }, 1500);
    } catch (error) {
      console.error('Error saving user detail:', error);
      setError('정보를 저장하는 중 오류가 발생했습니다.');
    } finally {
      setLoading(false);
    }
  };

  // 단계에 따른 렌더링 내용
  const renderStepContent = () => {
    switch (step) {
      case 1:
        return (
          <div className="modal-step">
            <h2>사용자님의 분야에 대해 간단한 질문을 드릴게요!</h2>
            <p>사용자님의 정보에 맞는 취업 정보를 엄선해드려요!</p>
            <div className="modal-image">
              <img src="" alt="Step 1" /> {/* 이미지를 프로젝트 경로에 맞게 설정 */}
            </div>
            <button className="modal-button" onClick={goToNextStep}>
              시작해봐요!
            </button>
          </div>
        );
      case 2:
        return (
          <div className="modal-step">
            <h2>사용자님의 분야를 선택해주세요!</h2>
            <p>(1/2 step)</p>
            <div className="field-selection">
              {[
                '개발/IT',
                '디자인',
                '인사/HR',
                '전략/마케팅',
                '회계/세무',
                '기획',
                '건설/인테리어',
                '경영',
                '보험/설계'
              ].map((field, index) => (
                <button
                  key={index}
                  className={`field-button ${selectedField === field ? 'selected' : ''}`}
                  onClick={() => handleFieldSelect(field)}
                >
                  {field}
                </button>
              ))}
            </div>
            {error && <p className="error">{error}</p>}
            <button
              className="modal-button"
              onClick={goToNextStep}
              disabled={!selectedField} // 선택된 항목이 없으면 버튼 비활성화
            >
              다음
            </button>
          </div>
        );
      case 3:
        return (
          <div className="modal-step">
            <h2>해당 분야에서의 경력을 선택해주세요!</h2>
            <p>(2/2 step)</p>
            <div className="experience-selection">
              {[
                '신입',
                '경력(1~2년)',
                '경력(3~6년)',
                '경력(7년 이상)'
              ].map((experience, index) => (
                <button
                  key={index}
                  className={`experience-button ${selectedExperience === experience ? 'selected' : ''}`}
                  onClick={() => handleExperienceSelect(experience)}
                >
                  {experience}
                </button>
              ))}
            </div>
            {error && <p className="error">{error}</p>}
            <button
              className="modal-button"
              onClick={handleSubmit}
              disabled={loading || !selectedExperience} // 로딩 중이거나 선택된 항목이 없으면 버튼 비활성화
            >
              {loading ? '저장 중...' : '끝내기'}
            </button>
          </div>
        );
      default:
        return null;
    }
  };

  return (
    <div className="modal">
      <div className="modal-content">
        {renderStepContent()}
        {successMessage && <p className="success">{successMessage}</p>}
      </div>
    </div>
  );
};

export default UserDetailModal;