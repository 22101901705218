import React, { useState } from 'react';
import Modal from 'react-modal';
import axios from 'axios';
import { setToken, getToken, removeToken } from '../utils/auth';
import './EmailSignupPage.css';

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

Modal.setAppElement('#root');

const EmailSignupPage = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [termsAccepted, setTermsAccepted] = useState(false);
  const [privacyPolicyAccepted, setPrivacyPolicyAccepted] = useState(false);
  const [marketingAccepted, setMarketingAccepted] = useState(false);
  const [isTermsModalOpen, setIsTermsModalOpen] = useState(false);
  const [isPrivacyPolicyModalOpen, setIsPrivacyPolicyModalOpen] = useState(false);
  const [isMarketingModalOpen, setIsMarketingModalOpen] = useState(false);
  const [verificationMessage, setVerificationMessage] = useState('');
  const [codeSentMessage, setCodeSentMessage] = useState('');

  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;

  const handleEmailSignup = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(`${BACKEND_URL}/auth/send-verification-code`, { email });
      if (response.data.token) {
        setToken(response.data.token);  // 서버에서 받은 토큰을 저장
      }
      alert('인증번호가 메일로 전송되었습니다.');
    } catch (error) {
      alert('인증 코드 전송 실패');
      console.error('인증 코드 전송 실패:', error);
    }
  };

  const handleVerifyCode = async (e) => {
    e.preventDefault();
    
    try {
      const token = getToken();  // 저장된 토큰을 가져옴
      const response = await axios.post(`${BACKEND_URL}/auth/verify-email-code`, { 
        inputCode: verificationCode,
        token
      });
      if (response.data.valid) {
        setVerificationMessage('인증이 완료되었습니다.');
        removeToken();  // 인증 완료 후 토큰 제거
      } else {
        setVerificationMessage('인증번호가 다릅니다.');
      }
    } catch (error) {
      setVerificationMessage('인증 코드 확인 실패');
      console.error('인증 코드 확인 실패:', error);
    }
  };

  const handleSignup = async (e) => {
    e.preventDefault();
    if (!termsAccepted || !privacyPolicyAccepted) {
      alert('필수 약관에 동의해야 합니다.');
      return;
    }
    if (!passwordRegex.test(password)) {
      alert('비밀번호는 8자 이상 20자 이하, 특수문자 1개 이상, 영문, 숫자를 포함해야 합니다.');
      return;
    }
    if (password !== confirmPassword) {
      alert('비밀번호가 일치하지 않습니다.');
      return;
    }
    try {
      const response = await axios.post(`${BACKEND_URL}/auth/register`, {
        username: name,
        email,
        password,
        marketingAccepted,
      });
      if (response.status === 201) {
        alert(response.data.msg);
        window.location.href = '/login';
      } else {
        alert(response.data.msg);
      }
    } catch (error) {
      console.error('회원가입 실패:', error);
      alert('회원가입 실패: ' + error.response.data.msg);
    }
  };

  const openTermsModal = () => {
    setIsTermsModalOpen(true);
  };

  const closeTermsModal = () => {
    setIsTermsModalOpen(false);
  };

  const openPrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(true);
  };

  const closePrivacyPolicyModal = () => {
    setIsPrivacyPolicyModalOpen(false);
  };

  const openMarketingModal = () => {
    setIsMarketingModalOpen(true);
  };
  
  const closeMarketingModal = () => {
    setIsMarketingModalOpen(false);
  };

  return (
    <div className="email-signup-page">
      <h1>Jobmaker</h1>
      <form onSubmit={handleSignup}>
        <div className="input-group">
          <label htmlFor="name">이름</label>
          <input
            id="name"
            type="text"
            placeholder="이름을 입력해주세요"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </div>
        
        <div className="input-group">
          <label htmlFor="email">이메일</label>
          <input
            id="email"
            type="email"
            placeholder="이메일 주소를 입력해주세요"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
          <button type="button" onClick={handleEmailSignup} className="verification-button">
            인증번호 발송
          </button>
        </div>
        
        {codeSentMessage && (
          <div className="code-sent-message">
            {codeSentMessage}
          </div>
        )}
        
        <div className="input-group">
          <label htmlFor="verificationCode">인증번호</label>
          <input
            id="verificationCode"
            type="text"
            placeholder="인증번호를 입력해주세요"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
          />
          <button type="button" onClick={handleVerifyCode} className="verification-button">
            확인
          </button>
        </div>
        
        {verificationMessage && (
          <div className={verificationMessage.includes('완료') ? 'verification-success' : 'verification-failure'}>
            {verificationMessage}
          </div>
        )}
        
        <div className="password-requirements">
          비밀번호는 8자 이상 20자 이하, 특수문자 1개 이상, 영문, 숫자를 포함해야 합니다.
        </div>
        
        <div className="input-group">
          <label htmlFor="password">비밀번호</label>
          <input
            id="password"
            type="password"
            placeholder="비밀번호를 입력해주세요"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        
        <div className="input-group">
          <label htmlFor="confirmPassword">비밀번호 확인</label>
          <input
            id="confirmPassword"
            type="password"
            placeholder="비밀번호를 다시 입력해주세요"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        
        {confirmPassword && (
          <div className={password === confirmPassword ? 'password-match' : 'password-mismatch'}>
            {password === confirmPassword ? '패스워드가 동일합니다' : '패스워드가 틀립니다'}
          </div>
        )}
        <div className="agreements">
          <label>
            <input
              type="checkbox"
              checked={termsAccepted}
              onChange={(e) => setTermsAccepted(e.target.checked)}
              required
            />
            <span onClick={openTermsModal}>이용약관 동의(필수)</span>
          </label>
          <label>
            <input
              type="checkbox"
              checked={privacyPolicyAccepted}
              onChange={(e) => setPrivacyPolicyAccepted(e.target.checked)}
              required
            />
            <span onClick={openPrivacyPolicyModal}>개인정보처리방침(필수)</span>
          </label>
          <label>
            <input
              type="checkbox"
              checked={marketingAccepted}
              onChange={(e) => setMarketingAccepted(e.target.checked)}
            />
            <span onClick={openMarketingModal}>마케팅수신동의(선택)</span>
          </label>
        </div>
        
        <button type="submit">가입하기</button>
      </form>

      <Modal
        isOpen={isTermsModalOpen}
        onRequestClose={closeTermsModal}
        contentLabel="Terms Modal"
        className="modal"
        overlayClassName="overlay"
        shouldCloseOnOverlayClick={true}  // 오버레이 클릭 시 모달이 닫히도록 설정
        shouldFocusAfterRender={true}     // 모달 렌더 후 포커스를 모달로 이동
      >
        <div className="modal-content">
          <h2>이용약관</h2>
          <div class="modal-text">
          사이트 이용약관<br /><br />
          제1조 (목적)<br />
          이 약관은 "잡메이커"가 온라인으로 제공하는 서비스의 이용과 관련하여 "회사"와 "이용자"의 권리·의무 등에 관한 사항을 규정함을 목적으로 합니다.<br /><br />
          
          제2조 (정의)<br />
          이 약관에서 사용하는 용어의 정의는 다음과 같습니다.<br />
          1. "회사"라 함은 "서비스"를 제공하는 자를 말합니다.<br />
          2. "이용자"라 함은 "회사"의 웹사이트에 접속하여 이용계약을 체결하여 "서비스"를 이용하는 "회원" 및 "비회원"을 말합니다.<br />
          3. "회원"이라 함은 회원가입을 하고 아이디(ID)를 부여받은 "이용자"를 말합니다.<br />
          4. "비회원"이라 함은 "회원"이 아니면서 "회사"가 제공하는 "서비스"를 이용하는 자를 말합니다.<br />
          5. "서비스"라 함은 전자적 방식으로 처리된 제반 사항을 말합니다.<br />
          6. "아이디(ID)"라 함은 "회원"의 식별과 서비스 이용을 목적으로 "회원"이 정하고 "회사"가 승인하는 부호·문자·숫자 등의 조합을 말합니다.<br />
          7. "비밀번호(PASSWORD)"라 함은 "아이디"와 일치되는 "회원"임을 확인하고 충분한 비밀성을 유지할 수 있도록 "회원"이 정한 부호·문자·숫자 등의 조합을 말합니다.<br /><br />
          
          제3조 (회사의 약관의 게시 및 정보제공의무)<br />
          ① "회사"는 이 약관의 내용 및 "회사"의 정보 등을 "이용자"가 쉽게 알 수 있도록 서비스 초기화면에 게시합니다.<br />
          ② "회사"는 "이용자"가 약관 전부를 인쇄하여 약관 내용을 확인하고, 약관에 관해 질의·응답할 수 있도록 기술적 조치를 취합니다.<br /><br />
          
          제4조 (약관의 개정)<br />
          ① "회사"가 약관을 개정할 경우에는 그 적용일자 7일 이전부터 적용일 후 상당한 기간 동안 공지합니다. 다만, "이용자"에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다.<br />
          ② "회사"가 약관을 개정할 경우에는 적용일자, 개정사유를 설명하고, 약관의 개정 전·후를 비교하여 "이용자"가 알기 쉽게 표시하여 서비스 초기화면에 공지합니다.<br />
          ③ "회사"가 약관을 개정할 경우에는 개정약관 공지 후 개정약관의 적용에 대한 "회원"의 동의 여부를 확인합니다. "회원"이 개정약관의 적용에 동의하지 않는 경우 "회사" 또는 "회원"은 "서비스" 회원가입계약을 해지할 수 있습니다.<br /><br />
          
          제5조 (관계법령의 준수)<br />
          ① 이 약관의 해석과 이 약관에서 정하지 아니한 사항에 관하여는 「이러닝(전자학습)산업 발전 및 이러닝 활용 촉진에 관한 법률」, 「콘텐츠산업진흥법」, 「전자상거래 등에서의 소비자보호에 관한 법률」, 「방문판매 등에 관한 법률」,「약관의 규제에 관한 법률」,「학원의 설립·운영 및 과외교습에 관한 법률」등 관련 법령 및 상관습에 따릅니다.<br />
          ② "회사"는 「개인정보보호법」및「정보통신망 이용촉진 및 정보보호 등에 관한 법률」에 따라 "이용자"의 개인정보를 관리·보호합니다.<br /><br />
          
          제2장 회원가입<br /><br />
          
          제6조 (회원가입)<br />
          ① 회원가입은 "이용자"가 약관 동의 및 회원가입 신청을 하고, "회사"가 "이용자"의 위 신청을 승낙함으로써 이루어집니다.<br />
          ② "이용자"는 회원가입 신청 시 다음 각 호의 사항을 기재해야 합니다.<br />
          1. "이용자"의 성명<br />
          2. "비밀번호"<br />
          3. "전자우편주소"<br />
          4. 기타 "회사"가 필요하다고 인정하는 사항<br />
          ③ "회사"는 "이용자"의 회원가입 신청에 대하여 승낙함을 원칙으로 합니다. 다만, "회사"는 신청자가 허위의 정보를 기재하거나 "회사"가 제시하는 내용을 기재하지 않은 경우에는 회원가입 신청을 거절할 수 있습니다.<br />
          ④ "회사"가 회원가입을 거절하는 경우 신청자에게 이를 알립니다.<br /><br />
          
          제7조 (만 14세 미만 미성년자에 관한 특칙)<br />
          ① 만 14세 미만의 "이용자"는 개인정보의 수집 및 이용목적을 충분히 숙지하고 부모 등 법정대리인의 동의를 얻은 후에 회원가입 신청을 하고 본인의 개인정보를 제공하여야 합니다.<br />
          ② "회사"는 부모 등 법정대리인의 동의에 대한 확인절차를 거치지 않은 만 14세 미만 "이용자"에 대하여는 회원가입을 불허 또는 취소할 수 있습니다.<br />
          ③ 만 14세 미만의 "이용자"의 부모 등 법정대리인은 아동에 대한 개인정보의 열람, 정정, 갱신을 요청하거나 회원가입에 대한 동의를 철회할 수 있으며, 이 경우 "회사"는 지체 없이 필요한 조치를 취해야 합니다.<br /><br />
          
          제8조 (회원정보의 변경)<br />
          ① "회원"은 회원가입 신청 시 기재한 개인정보가 변경되었을 경우 온라인으로 변경사항을 수정하거나, 전자우편 등 기타 방법으로 "회사"에 이를 알려야 합니다.<br />
          ② "회사"는 "회원"이 개인정보 변경사항을 수정하지 않거나, "회사"에 알리지 않아 발생한 불이익에 대해서는 책임지지 않습니다.<br /><br />
          
          제9조 (회원의 아이디와 비밀번호 관리에 관한 주의의무)<br />
          ① "회원"의 "아이디"와 "비밀번호"에 관한 관리책임은 "회원"에게 있으며, 이를 제3자가 이용하도록 해서는 안 됩니다.<br />
          ② "회원"은 "아이디" 및 "비밀번호"가 도용되거나 제3자에 의해 사용되고 있음을 인지한 경우에는 이를 즉시 "회사"에 통지하고 "회사"의 안내에 따라야 합니다.<br />
          ③ "회원"이 "회사"에 제3자의 도용사실을 통지하지 않은 경우 또는 통지하였더라도 "회사"의 안내에 따르지 않은 경우에는 "회사"는 이로 인해 발생한 불이익에 대하여 책임지지 않습니다.<br /><br />
          
          제10조 (회원 탈퇴와 자격 제한 및 상실)<br />
          ① "회원"은 "회사"에 언제든지 탈퇴를 요청할 수 있으며, "회사"는 즉시 회원탈퇴를 처리합니다.<br />
          ② "회원"이 다음 각 호의 부정이용 행위를 하는 경우, "회사"는 회원자격을 제한 또는 정지시킬 수 있습니다.<br />
          1. 자신의 "아이디"를 타인이 이용하도록 하는 경우<br />
          2. 자신의 "아이디"를 타인에게 판매·대여하거나 이를 광고하는 경우<br />
          3. 기타 ��당한 방법으로 "아이디"를 사용하는 경우<br />
          4. "서비스 콘텐츠"를 복제하는 경우<br />
          ③ 회원자격의 제한·정지 후에도 "회원"이 동일한 행위를 반복하거나 30일 이내에 그 사유를 시정하지 아니하는 경우, "회사"는 회원자격을 상실시킬 수 있습니다.<br />
          ④ "회사"는 회원자격을 상실시키는 경우, 회원등록을 말소하고 "아이디"를 삭제합니다. "회사"는 "회원"에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.<br /><br />
          
          제3장 서비스 이용계약<br /><br />
          
          제11조 (서비스 이용계약의 내용)<br />
          "회사"는 "이용자"에 대하여 "서비스"를 제공하며, "이용자"는 "회사"에 대하여 유료서비스의 경우 이용대금을 지급합니다.<br /><br />
          
          제12조 (서비스 내용 등 거래조건에 관한 정보의 제공)<br />
          "회사"는 다음 각 호의 사항을 해당 "서비스"의 초기화면에 "이용자"가 알기 쉽게 표시합니다.<br />
          1. "서비스"의 명칭 또는 제호<br />
          2. "서비스"의 내용·이용방법·이용기간·이용대금 등 기타 이용조건<br /><br />
          
          제13조 (서비스 이용계약의 성립과 절차)<br />
          ① "서비스" 이용계약은 "이용자"가 "회사"에 대하여 이용신청을 하고, "회사"가 이를 승낙함으로써 체결됩니다.<br />
          ② "회사"는 계약 체결 전에 "이용자"가 착오 없이 계약을 체결할 수 있도록 "이용자"에게 다음 각 호의 정보를 제공하거나, 확인하도록 합니다.<br />
          1. "서비스" 내용<br />
          2. 약관내용 및 청약철회에 관한 사항<br />
          3. "서비스"의 신청에 관한 확인 또는 "회사"의 확인에 대한 동의<br />
          4. 결제금액의 확인 및 결제수단의 선택<br />
          ③ "회사"는 "이용자"의 신청이 다음 각 호에 해당하는 경우에는 승낙을 거절하거나 유보할 수 있습니다.<br />
          1. 허위의 정보를 기재하거나, "회사"가 제시하는 내용을 기재하지 않은 경우<br />
          2. 서비스의 이용대금을 납입하지 않은 경우<br />
          ④ "회사"의 승낙의 의사표시는 "이용자"의 이용신청에 대한 확인, 서비스제공 가능여부 및 신청의 정정·취소 등에 관한 정보 등을 포함합니다.<br /><br />
          
          제14조 (회사의 이용신청확인 통지와 이용자의 이용신청 변경 및 취소)<br />
          ① "회사"는 "이용자"가 이용신청을 한 경우 "이용자"의 전자우편으로 이용신청확인 통지를 합니다. 통지를 받은 "이용자"는 의사표시의 불일치 등이 있는 경우에는 즉시 이용신청의 변경 또는 취소를 요청할 수 있습니다.<br />
          ② "회사"는 "서비스"의 제공 이전에 "이용자"로부터 제1항의 요청을 받은 경우 이를 지체 없이 수락하여야 합니다. 다만, "서비스"가 제공된 이후에는 제22조 [이용자의 청약철회와 그 효과]에 따라 처리합니다.<br /><br />
          
          제15조 (미성년자의 이용계약에 관한 특칙)<br />
          ① 필요시 "회사"는 만 14세 미만의 미성년 "이용자"가 유료 "서비스"를 이용하고자 하는 경우 부모 등 법정대리인의 동의 여부를 확인해야 합니다.<br />
          ② 미성년 "이용자"의 부모 등 법정대리인은 본인의 동의 없이 체결된 "서비스" 이용계약을 취소할 수 있습니다.<br /><br />
          
          제16조 (이용자에 대한 통지 및 공지)<br />
          ① "회사"가 "이용자" 개인에 대한 통지를 하는 경우 "이용자"가 지정한 전자우편주소로 합니다.<br />
          ② "회사"가 "이용자" 전체에 대한 통지를 하는 경우 7일 이상 서비스 초기화면에 공지함으로써 제1항의 통지에 갈음할 수 있습니다. 다만, "서비스" 이용과 관련한 중대한 사항에 대하여는 "이용자"에게 제1항의 방법에 따라 통지합니다.<br /><br />
          
          제17조 (서비스의 제공과 중지·중단)<br />
          ① "서비스"는 연중무휴, 1일 24시간 제공함을 원칙으로 합니다.<br />
          ② "회사"는 컴퓨터 등 정보통신설비의 보수·점검·교체·고장·통신두절 또는 운영상 상당한 이유가 있는 경우 "서비스"의 제공을 일시적으로 중지할 수 있습니다. 이 경우 "회사"는 사전에 "이용자"에게 중지사실을 공지 또는 통지합니다.<br />
          ③ "회사"는 「소비자분쟁해결기준」에 따라 "서비스"의 중지·장애로 인한 "이용자"의 피해를 보상합니다.<br />
          ④ "회사"는 사업의 포기, 사업종목의 전환, 업체 간의 통합 등의 이유로 "서비스"를 제공할 수 없게 되는 경우(서비스 중단)에는 "이용자"에게 그 사실을 통지하고, 통지한 날로부터 3 영업일 이내에 "이용자"의 이용금액을 공제하고 이용대금을 환급합니다.<br /><br />
          
          제18조 (서비스의 변경)<br />
          ① "회사"는 상당한 운영상 또는 기술상의 필요가 있는 경우에 "이용자"에게 제공되고 있는 "서비스"의 이용방법·이용시간을 변경할 수 있습니다. 이 경우 "회사"는 변경일시·내용·사유 등을 그 변경 전 7일 이상 해당 "서비스" 초기화면에 게시하고, "이용자"에게 통지합니다.<br />
          ② 제1항의 경우에 "이용자"는 "회사"에 대하여 변경 이전의 서비스 제공을 요구할 수 있습니다. 다만, 이것이 불가능할 경우 "회사" 또는 "이용자"는 계약을 해지할 수 있습니다.<br /><br />
          
          제19조 (게시물의 삭제 등)<br />
          ① "회사"는 운영하는 게시판에 「청소년 보호법」상 '청소년유해매체물' 등이 게시되어 있는 경우에는 이를 지체 없이 삭제합니다.<br />
          ② "회사"가 게시판 등에 게시된 정보로 인하여 법률상·사회상 이익이 침해된 자는 "회사"에게 당해 정보의 삭제 또는 반론 게시물의 게재를 요청할 수 있습니다. 이 경우 "회사"는 지체 없이 필요한 조치를 취하고, 처리결과를 요청한 "이용자"에게 통지합니다.<br /><br />
          
          제20조 (이용자의 이용대금 지급의무)<br />
          ① "이용자"는 "서비스"이용계약에 따라 정해진 이용대금을 지급하여야 합니다.<br />
          ② "회사"는 "이용자"가 선택한 결제수단에 대하여 어떠한 명목의 수수료도 추가하여 징수하지 않습니다.<br /><br />
          
          제21조 (이용자의 주의의무)<br />
          ① "이용자"는 이 약관의 규정, 관계법령 및 "회사"가 통지 및 공지하는 사항 등을 준수하여야 하며, 기타 "회사"의 업무에 방해되는 행위를 하여서는 안 됩니다.<br />
          ② "이용자"는 "서비스"의 이용권한, 기타 이용계약상 지위를 타인에게 양도, 증여할 수 없습니다.<br />
          ③ "이용자"는 "회사" 또는 "서비스제공자"의 지식재산을 "회사" 또는 제공업체의 사전승낙 없이 복제·전송·출판·배포·방송 기타 방법에 의하여 영리목적으로 이용하거나 제3자에게 이용하게 하여서는 안 됩니다.<br /><br />
          
          제4장 서비스 이용계약의 청약철회, 해제·해지<br /><br />
          
          제22조 (이용자의 청약철회와 그 효과)<br />
          ① "이용자"의 청약철회는 "이용자"가 전화·전자우편 등으로 "회사"에 그 의사를 표시한 때에 효력이 발생합니다. "회사"는 "이용자"의 청약철회의 의사표시를 수신한 후 지체 없이 이러한 사실을 "이용자"에게 회신합니다.<br />
          ② "회사"는 "이용자"가 청약철회의 의사표시를 한 날로부터 3 영업일 이내에 "이용자"의 이용금액을 공제하고 나머지 이용대금을 환급합니다.<br />
          ③ 청약을 철회할 때 교재 등 반환이 필요한 경우에는 해당 비용은 "이용자"가 이를 부담합니다.<br />
          ④ "회사"는 청약철회를 이유로 "이용자"에게 위약금 또는 손해배상을 청구하지 않습니다.<br /><br />
          
          제23조 (이용자의 계약해제 및 해지)<br />
          ① "이용자"는 다음 각 호의 사유가 있을 때에는 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내 또는 해당 "서비스"를 공급받은 날로부터 3월 이내에 "서비스" 이용계약을 해제할 수 있습니다.<br />
          1. "서비스"가 제공되지 않거나, 제공된 "서비스"가 표시·광고 등과 현저한 차이가 있는 경우<br />
          2. 기타 "서비스"의 결함으로 인하여 정상적인 이용이 불가능한 경우<br /><br />
          
          제24조 (이용자의 계약해제·해지의 효과)<br />
          ① "회사"는 "이용자"로부터 계약해제·해지의 의사표시를 받은 날로부터 3 영업일 이내에 이용대금을 환급합니다.<br />
          ② "이용자"가 제23조 제1항에 따라 계약을 해제하는 경우에는 "회사"는 이용대금 전액을 환급합니다.<br /><br />
          
          제25조 (회사의 계약해제·해지 및 그 효과)<br />
          ① "회사"는 "이용자"가 책임 있는 사유로 이 약관에서 정한 의무를 위반함으로써 "서비스" 이용계약관계를 유지할 수 없을 정도로 "회사"와의 신뢰관계를 저버린 경우에는 계약을 해제·해지할 수 있습니다.<br />
          ② "회사"는 "이용자"에게 계약해제·해지의 의사표시를 한 날로부터 7 영업일 이내에 제26조 제3항의 환급기준에 따라 이용대금을 환급합니다.<br /><br />
          
          제26조 (이용자의 이용금액 산정)<br />
          ① 요금별 "회사"의 정책에 따라 환불합니다.<br /><br />
          
          제27조 (환급수단 및 환급금에 대한 지연이자 등)<br />
          ① "회사"가 "이용자"에게 이용대금 등을 환급하는 경우에는 원칙적으로 "이용자"가 대금을 결제한 때와 동일한 수단으로 환급합니다. 동일한 수단으로 환급이 불가능할 경우에는 그 사유와 다른 환급수단을 사전에 고지합니다.<br />
          ② "회사"가 "이용자"에게 환급금 지급을 지연한 경우에는 그 지연기간에 대하여 연 100분의 20을 곱하여 산정한 지연이자를 지급합니다.<br /><br />
          
          제5장 분쟁 해결<br /><br />
          
          제28조 (회사의 면책조항)<br />
          ① "회사"는 천재지변 또는 이에 준하는 불가항력으로 인하여 "서비스"를 제공할 수 없는 경우에는 "서비스" 제공에 관한 책임이 면제됩니다.<br />
          ② "회사"는 "이용자" 상호간 또는 "이용자"와 제3자 간에 "서비스"를 매개로 하여 발생한 분쟁 등에 대하여 책임을 지지 않습니다.<br /><br />
          
          제29조 (분쟁의 해결)<br />
          "회사"는 분쟁이 발생하였을 경우에 "이용자"가 제기하는 정당한 의견이나 불만을 반영하여 적절하고 신속한 조치를 취합니다. 다만, 신속한 처리가 곤란한 경우에 "회사"는 "이용자"에게 그 사유와 처리일정을 통보합니다.<br /><br />
          
          부칙 : 이 약관은 2023년 3월 1일부터 적용됩니다.<br />
        </div>
          <button onClick={closeTermsModal}>닫기</button>
        </div>
      </Modal>

      <Modal
        isOpen={isPrivacyPolicyModalOpen}
        onRequestClose={closePrivacyPolicyModal}
        contentLabel="Privacy Policy Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <div className="modal-content">
          <h2>개인정보처리방침</h2>
          개인정보처리방침<br /><br />
          "잡메이커"는 정보통신망 이용촉진 및 정보보호 등에 관한 법률, 개인정보보호법 등 정보통신서비스제공자가 준수하여야 할 관련 법령상의 개인정보보호 규정을 준수하며, 관련 법령에 의거한 개인정보처리방침을 정하여 이용자 권익 보호에 최선을 다하고 있습니다. 본 개인정보처리방침은 회사가 제공하는 서비스 이용에 적용되며 다음과 같은 내용을 담고 있습니다.<br /><br />
          1. 이용자의 권리와 그 행사방법<br />
          2. 개인정보보호책임자 및 담당자의 연락처<br />
          3. 개인정보 자동수집 장치의 설치/운영 및 거부 사항<br />
          4. 개인정보의 수집/이용 목적, 수집하는 개인정보의 항목 및 수집 방법<br />
          5. 제3자 제공 현황<br />
          6. 개인정보의 보유 및 이용기간, 파기절차 및 방법<br />
          7. 개인정보의 처리위탁<br />
          8. 개인정보의 기술적/관리적 보호 조치<br />
          9. 개인정보 관련 의견수렴 및 불만처리<br />
          10. 고지의 의무<br /><br />

          1. 이용자의 권리와 그 행사 방법<br />
          • 이용자는 언제든지 등록된 자신의 개인정보를 조회하거나 수정할 수 있으며, 개인정보 처리에 동의하지 않는 경우 동의를 거부하거나 가입 해지(회원탈퇴)를 요청하실 수 있습니다. 다만, 그러한 경우 서비스의 일부 또는 전부 이용이 어려울 수 있습니다.<br />
          • 이용자 개인정보 조회, 수정을 위해서는 '개인정보변경'(또는 '회원정보수정' 등)을, 가입해지(동의철회)를 위해서는 '회원탈퇴'를 클릭하여 본인 확인 절차를 거치신 후 직접 열람, 정정 또는 탈퇴가 가능합니다.<br />
          • 개인정보관리책임자에게 서면, 전화 또는 이메일로 연락하시면 지체 없이 조치하겠습니다.<br />
          • 이용자가 개인정보의 오류에 대한 정정을 요청하신 경우에는 정정을 완료하기 전까지 당해 개인정보를 이용 또는 제공하지 않습니다. 또한, 잘못된 개인정보를 제3자에게 이미 제공한 경우에는 정정 처리결과를 제3자에게 지체 없이 통지하여 정정이 이루어지도록 하겠습니다.<br />
          • 이용자의 요청으로 해지 또는 삭제된 개인정보는 "6. 개인정보의 보유 및 이용기간, 파기절차 및 방법"에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리하고 있습니다.<br /><br />

          2. 개인정보보호책임자 및 담당자의 연락처<br />
          • 회원의 개인정보를 보호하고 개인정보와 관련된 불만을 처리하기 위하여 회사는 개인정보보호 책임자를 두고 있습니다. 개인정보와 관련된 문의사항이 있으시면 아래의 연락처로 연락 주시기 바랍니다.<br />
          개인정보 보호책임자<br />
          이름: 홍길동<br />
          직위: 소장<br />
          소속: 기획정보실<br />
          이메일: jobmaker.ai@gmail.com<br />
          전화번호: 02-1234-5678<br /><br />

          3. 개인정보 자동수집 장치의 설치/운영 거부의 사항<br />
          가. 회사의 쿠키 사용목적<br />
          • 회원과 비회원의 접속 빈도나 방문 시간 등을 분석<br />
          • 이용자의 취향과 관심분야를 파악 및 자취 추적<br />
          • 각종 이벤트 참여 정도 및 방문 횟수 파악 등을 통한 타켓 마케팅 및 개인 맞춤 서비스 제공<br /><br />

          나. 쿠키의 설치/운영 및 거부<br />
          • 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서 이용자는 웹 브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br />
          • 다만, 쿠키의 저장을 거부할 경우에는 로그인이 필요한 일부 서비스는 이용에 어려움이 있을 수 있습니다.<br /><br />

          4. 개인정보의 수집/이용 목적, 수집하는 개인정보의 항목 및 수집 방법<br />
          가. 개인정보의 수집/이용 목적<br />
          1. 회원관리<br />
          • 회원제 서비스 제공, 개인식별, 불량회원의 부정 이용 방지와 비인가 사용방지, 가입의사 확인, 연령확인, 미성년자 결제 시 법정대리인 동의여부 확인, 불만처리 등 민원처리, 고지사항 전달 등<br /><br />

          2. 서비스 이용<br />
          • 서비스 제공에 관한 계약 이행 및 서비스 제공에 따른 요금 정산, 콘텐츠 제공, 구매 및 요금 결제, 물품배송 또는 청구지 발송<br /><br />

          3. 서비스 개발 및 마케팅 광고에의 활용<br />
          • 이벤트 등 광고성 정보 전달, 인구통계학적 특성에 따른 서비스 제공 및 광고 게재, 접속 빈도 파악 또는 회원의 서비스 이용에 대한 통계<br /><br />

          4. Google Analytics 활용<br />
          • 회사는 이용자에게 더 나은 서비스를 제공하기 위한 목적으로 Google, Inc. 이 제공하는 웹 분석 서비스인 Google Analytics를 사용하여 이용자들이 회사의 서비스를 어떻게 이용하는지 분석 및 평가하고 이용자의 수요를 파악하며, 서비스와 상품을 개선하고 맞춤화하여 효율적인 서비스를 제공하는 것에 목적이 있습니다.<br /><br />

          나. 수집하는 개인정보의 항목 및 수집 방법<br />
          1. 수집하는 개인정보의 항목<br />
          • 회원가입: 이름, 아이디, 비밀번호, 휴대폰번호, 이메일, 주소<br />
          • 모바일앱 이용 시: 휴대폰번호 및 단말기(스마트폰, 태블릿PC 등)의 기기정보, 위치정보<br />
          • 신용카드 결제 시: 거래자명, 카드사명, 카드번호, 카드유효기간<br />
          • 계좌이체 시: 은행명, 계좌번호<br />
          • 서비스신청 시: 성명, 휴대폰, 전화번호, 이메일, 신청내용 외<br />
          • 서비스 이용과정에서 IP주소, 쿠키, 방문 일시, 서비스이용기록 정보들이 자동으로 생성되어 수집될 수 있습니다.<br /><br />

          2. 개인정보 수집 방법<br />
          • 웹페이지 회원가입 및 서비스 이용 과정에서 이용자가 개인정보 수집에 대해 동의를 하고 직접 정보를 입력하는 경우, 해당 개인정보를 수집합니다. (회원가입, 상담예약신청)<br />
          • 고객센터를 통한 상담 과정에서 웹페이지, 메일, 팩스, 전화 등을 통해 이용자의 개인정보가 수집될 수 있습니다.<br />
          • 오프라인에서 진행되는 이벤트, 설명회 등에서 서면을 통해 개인정보가 수집될 수 있습니다.<br />
          • 회사와 제휴한 외부 기업이나 단체로부터 개인정보를 제공받을 수 있으며, 이러한 경우에는 정보통신망법에 따라 제휴사에서 이용자에게 개인정보 제공 동의 등을 받은 후에 당사에 제공합니다.<br />
          • 기기정보와 같은 생성정보는 PC웹, 모바일 웹/앱 이용 과정에서 자동으로 생성되어 수집될 수 있습니다.<br /><br />

          5. 제3자 제공 현황<br />
          가. 회사는 이용자들의 개인정보를 "4. 개인정보의 수집/이용 목적, 수집하는 개인정보의 항목 및 수집 방법"에서 고지한 범위내에서 사용하며, 이용자의 사전 동의 없이는 동 범위를 초과하여 이용하거나 원칙적으로 이용자의 개인정보를 외부에 공개하지 않습니다.<br />
          나. 다만, 아래의 경우에는 예외로 합니다.<br />
          • 이용자가 동의한 경우<br />
          • 법령의 규정에 의거하거나, 수사 목적으로 법령에 정해진 절차와 방법에 따라 수사기관의 요구가 있는 경우<br /><br />

          6. 개인정보의 보유 및 이용기간, 파기절차 및 방법<br />
          가. 개인정보의 보유 및 이용기간<br />
          1. 원칙적으로 개인정보 수집 및 이용목적이 달성된 후에는 해당 정보를 지체 없이 파기합니다. (단, 기타 개별적으로 동의를 받은 경우에는 동의한 기간 동안 보유합니다.)<br />
          2. 관계법령의 규정에 의하여 보존할 필요가 있는 경우 회사는 아래와 같이 관계법령에서 정한 일정한 기간 동안 회원정보를 보유합니다.<br />
          ◦ 계약 또는 청약 철회 등에 관한 기록<br />
          ▪ 보존이유: 전자상거래 등에서의 소비자보호에 관한 법률<br />
          ▪ 보존기간: 5년<br />
          ◦ 대금결제 및 재화 등의 공급에 관한 기록<br />
          ▪ 보존이유: 전자상거래 등에서의 소비자보호에 관한 법률<br />
          ▪ 보존기간: 5년<br />
          ◦ 전자금융거래에 관한 기록<br />
          ▪ 보존이유: 전자금융거래법<br />
          ▪ 보존기간: 5년<br />
          ◦ 소비자의 불만 또는 분쟁처리에 관한 기록<br />
          ▪ 보존이유: 전자상거래 등에서의 소비자보호에 관한 법률<br />
          ▪ 보존기간: 3년<br />
          ◦ 부정이용 등에 관한 기록<br />
          ▪ 보존이유: 재가입 방지<br />
          ▪ 보존기간: 1년<br /><br />

          나. 파기절차 및 방법<br />
          1. 파기절차<br />
          ◦ 이용자가 회원가입 등을 위해 입력한 정보는 목적이 달성된 후 별도의 DB로 옮겨져(종이의 경우 별도의 서류함) 내부 방침 및 기타 관련 법령에 의한 정보보호 사유에 따라 일정 기간 저장된 후 파기됩니다.<br />
          2. 파기 방법<br />
          ◦ 종이에 출력된 개인정보는 분쇄기로 분쇄하거나 소각을 통하여 파기합니다.<br />
          ◦ 전자적 파일 형태로 저장된 개인정보는 기록을 재생할 수 없는 기술적 방법을 사용하여 삭제합니다.<br />
          ◦ 참고로 "개인정보 유효기간제"에 따라 1년 또는 선택한 기간(5년) 동안 서비스를 이용하지 않은 회원의 개인정보를 삭제하거나 별도로 분리 보관하여 관리하고 있습니다.<br /><br />

          7. 개인정보의 처리위탁<br />
          가. 회사는 서비스 향상을 위해서 아래와 같이 개인정보를 위탁하고 있으며, 관계 법령에 따라 위탁계약 시 개인정보가 안전하게 관리될 수 있도록 필요한 사항을 규정하고 있습니다.<br /><br />

          8. 개인정보의 기술적/관리적 보호 조치<br />
          가. 회사는 이용자들의 개인정보를 처리하면서 개인정보가 분실, 도난, 누출, 변조 또는 훼손되지 않도록 안전성 확보를 위하여 다음과 같은 기술적/관리적 대책을 마련하고 있습니다.<br />
          1. 비밀번호 암호화<br />
          ◦ 회원 아이디의 비밀번호는 암호화되어 저장 및 관리되고 있어 본인만이 알고 있으며, 개인정보의 확인 및 변경도 비밀번호를 알고 있는 본인에 의해서만 가능합니다.<br />
          2. 해킹 등에 대비한 대책<br />
          ◦ 해킹이나 바이러스 등에 의해 회원의 개인정보가 유출되거나 훼손되는 것을 막기 위해 최선을 다하고 있습니다. 개인정보의 훼손에 대비하여 자료를 수시로 백업하고 있으며, 최신 백신프로그램을 이용하여 이용자들의 개인정보나 자료가 누출되거나 손상되지 않도록 방지하고 있습니다.<br />
          ◦ 침입차단시스템을 이용하여 외부로부터의 무단 접근을 통제하고 있으며, 기타 시스템적으로 보안성을 확보하기 위한 가능한 모든 기술적 장치를 갖추려 노력하고 있습니다.<br />
          3. 처리 직원의 최소화 및 교육<br />
          ◦ 회사의 개인정보관련 처리 직원은 담당자에 한정시키고 있으며 담당자에 대한 정기적인 교육을 통하여 개인정보처리방침의 준수를 강조하고 있습니다.<br />
          4. 개인정보담당자의 지정<br />
          ◦ 사내 개인정보담당자를 지정하여 개인정보처리방침의 이행사항 및 담당자의 준수여부를 확인하여 문제가 발견될 경우 즉시 수정하고 바로 잡을 수 있도록 노력하고 있습니다.<br />
          ◦ 단, 이용자 본인의 부주의나 인터넷상의 문제로 ID, 비밀번호 등 개인정보가 유출되어 발생한 문제에 대해 회사는 일체의 책임을 지지 않습니다.<br /><br />

          9. 개인정보 관련 의견수렴 및 불만처리<br />
          가. 회사는 정보주체의 의견을 소중하게 생각하며, 정보주체는 의문사항에 대해 언제나 성실한 답변을 받을 권리가 있습니다.<br />
          1. 고객센터<br />
          ◦ 전자우편: jobmaker.ai@gmail.com<br />
          ◦ 전화번호: 02-1234-5678<br />
          ◦ 주소: 부산광역시 남구<br />
          2. 기타 개인정보침해에 대한 신고나 상담이 필요한 경우에는 아래 기관에 문의하시기 바랍니다.<br />
          ◦ 개인정보침해신고센터: 118 / privacy.kisa.or.kr<br />
          ◦ 대검찰청 사이버범죄수사단: 1301 / www.spo.go.kr<br />
          ◦ 경찰청 사이버안전국: 182 / cyberbureau.go.kr<br />
          ◦ 개인정보분쟁조정위원회: 1833-6972 / www.kopico.go.kr<br /><br />

          10. 고지의 의무<br />
          현 개인정보처리방침 내용 추가, 삭제 및 수정이 있을 시에는 개정 최소 7일 전부터 홈페이지의 '공지사항'을 통해 고지할 것입니다.<br />
          1. 공고일자: 2024년 2월 1일<br />
          2. 시행일자: 2024년 2월 1일<br />
          <button onClick={closePrivacyPolicyModal}>닫기</button>
        </div>
      </Modal>
      {/* 마케팅 수신동의 모달 */}
      <Modal
        isOpen={isMarketingModalOpen}
        onRequestClose={closeMarketingModal}
        contentLabel="Marketing Consent Modal"
        className="modal"
        overlayClassName="overlay"
      >
        <div className="modal-content">
          <h2>마케팅 수신동의</h2>
          <div className="modal-text">
          <p><strong>1. 수집하는 개인정보 항목</strong></p>
          <ul>
            <li>이메일 주소</li>
            <li>휴대전화 번호</li>
          </ul>
          <p><strong>2. 마케팅 정보 수신 목적</strong></p>
          <ul>
            <li>이벤트 및 프로모션 정보 제공</li>
            <li>신규 서비스 안내</li>
            <li>뉴스레터 발송</li>
            <li>고객 맞춤형 광고 제공</li>
          </ul>
          <p><strong>3. 마케팅 정보 수신 방법</strong></p>
          <ul>
            <li>이메일</li>
            <li>문자메시지(SMS/MMS)</li>
            <li>푸시 알림</li>
          </ul>
          <p><strong>4. 동의 철회</strong></p>
          <ul>
            <li>이용자는 언제든지 마케팅 정보 수신 동의를 철회할 수 있습니다.</li>
            <li>동의 철회는 이메일 하단의 '수신 거부' 링크 클릭 또는 고객센터로 연락하여 처리할 수 있습니다.</li>
          </ul>
          <p><strong>5. 기타 사항</strong></p>
          <ul>
            <li>마케팅 수신 동의는 선택 사항이며, 동의하지 않더라도 서비스 이용에 제한이 없습니다.</li>
            <li>자세한 사항은 <a href="개인정보처리방침 링크">개인정보처리방침</a>에서 확인할 수 있습니다.</li>
          </ul>
          </div>
          <button onClick={closeMarketingModal}>닫기</button>
        </div>
      </Modal>
    </div>
  );
};

export default EmailSignupPage;