import React, { useState } from 'react';
import axios from 'axios';
import { getToken } from '../../../utils/auth';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const ResumeEvaluationResult = ({ evaluation, onReset }) => {
  const [showResumeContent, setShowResumeContent] = useState(false);

  if (!evaluation) {
    return <div>평가 결과를 불러오는 중입니다...</div>;
  }

  const handleCopyText = () => {
    const textToCopy = `이력서 평가 결과:
총점: ${evaluation.totalScore}
적합도: ${evaluation.fitForRole}
전반적인 평가: ${evaluation.overallEvaluation}
강점: ${evaluation.strengths ? evaluation.strengths.join(', ') : '없음'}
개선이 필요한 부분: ${evaluation.areasForImprovement ? evaluation.areasForImprovement.join(', ') : '없음'}
개선 제안: ${evaluation.suggestedImprovements ? evaluation.suggestedImprovements.join(', ') : '없음'}`;

    navigator.clipboard.writeText(textToCopy)
      .then(() => alert('평가 결과가 클립보드에 복사되었습니다.'))
      .catch(err => console.error('텍스트 복사 중 오류 발생:', err));
  };

  const handleSave = async () => {
    try {
      const token = getToken();  // getToken 함수를 사용하여 토큰을 가져옵니다.
      console.log('사용 중인 토큰:', token); // 토큰 로그 출력 (디버깅 목적)

      const response = await axios.post(
        `http://localhost:3001/resume-evaluation/save/${evaluation._id}`,
        {},
        {
          headers: { 
            'x-auth-token': token,
            'Content-Type': 'application/json'
          }
        }
      );

      if (response.data.success) {
        alert('평가 결과가 성공적으로 저장되었습니다.');
      } else {
        alert('평가 결과 저장에 실패했습니다.');
      }
    } catch (error) {
      console.error('평가 결과 저장 중 오류 발생:', error);
      console.log('오류 응답:', error.response); // 오류 응답 상세 정보 출력
      alert('평가 결과 저장 중 오류가 발생했습니다.');
    }
  };

  const renderRadarChart = () => {
    if (!evaluation || !evaluation.graphData) return null;

    const data = {
      labels: evaluation.graphData.map(item => item.category),
      datasets: [{
        label: '점수',
        data: evaluation.graphData.map(item => item.score),
        backgroundColor: 'rgba(255, 99, 132, 0.2)',
        borderColor: 'rgba(255, 99, 132, 1)',
        borderWidth: 1
      }]
    };

    const options = {
      scales: {
        r: {
          beginAtZero: true,
          max: Math.max(...evaluation.graphData.map(item => item.maxScore))
        }
      }
    };

    return <Radar data={data} options={options} />;
  };

  return (
    <div className="resume-evaluation-result">
      <h2>이력서 평가 결과</h2>
      <div className="score-container">
        <p className="score-text">당신의 이력서 점수는</p>
        <p className="score-value">{evaluation.totalScore || 0}점</p>
        <p className="score-text">입니다.</p>
        <p className="fit-for-role">적합도: {evaluation.fitForRole || '평가 중'}</p>
      </div>
      <div className="feedback-section">
        <h3>전반적인 평가</h3>
        <p>{evaluation.overallEvaluation || '평가 중'}</p>
      </div>
      {evaluation.sectionFeedback && evaluation.sectionFeedback.length > 0 && (
        <div className="feedback-section">
          <h3>섹션별 피드백</h3>
          {evaluation.sectionFeedback.map((feedback, index) => (
            <div key={index} className="section-feedback">
              <h4>{feedback.section}</h4>
              <p>{feedback.feedback}</p>
            </div>
          ))}
        </div>
      )}
      {evaluation.strengths && evaluation.strengths.length > 0 && (
        <div className="feedback-section">
          <h3>강점</h3>
          <ul>
            {evaluation.strengths.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}
      {evaluation.areasForImprovement && evaluation.areasForImprovement.length > 0 && (
        <div className="feedback-section">
          <h3>개선이 필요한 부분</h3>
          <ul>
            {evaluation.areasForImprovement.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}
      {evaluation.suggestedImprovements && evaluation.suggestedImprovements.length > 0 && (
        <div className="feedback-section">
          <h3>개선 제안</h3>
          <ul>
            {evaluation.suggestedImprovements.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      )}
      <div className="score-graph">
        <h3>점수 그래프</h3>
        {renderRadarChart()}
      </div>
      <div className="button-container">
        <button onClick={onReset} className="reset-button">돌아가기</button>
        <button onClick={handleCopyText} className="copy-button">텍스트 복사하기</button>
        <button onClick={handleSave} className="save-button">평가 저장하기</button>
      </div>
    </div>
  );
};

export default ResumeEvaluationResult;