import React from 'react';
import ResumeEvaluation from './ResumeEvaluation';
import './ResumeEvaluationPage.css';

const ResumeEvaluationPage = () => {
    return (
        <div className="resume-evaluation-page">
            <h1>이력서 평가</h1>
            <ResumeEvaluation />
        </div>
    );
};

export default ResumeEvaluationPage;