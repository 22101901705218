import React from 'react';
import { FaUpload, FaTrashAlt } from 'react-icons/fa';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ImageGuide from './ImageGuide';

const PhotoUploadStep = ({ mainPhoto, setMainPhoto, handleNextStep }) => {
  const handleMainPhotoUpload = (event) => {
    const file = event.target.files[0];
    setMainPhoto(file);
  };

  const removeMainPhoto = () => {
    setMainPhoto(null);
  };

  return (
    <div className="photo-upload-step">
      <ToastContainer />
      <h1 className="photo-upload-step__title">증명사진 생성기</h1>
      <h2 className="photo-upload-step__subtitle">사진 업로드 (1/2 Step)</h2>
      <div className="photo-upload-step__upload-container">
        <div className="photo-upload-step__upload-section">
          <label className="photo-upload-step__label">
            정면 사진 1장 <span className="photo-upload-step__required">*</span>
          </label>
          <div className="photo-upload-step__input-container">
            <div className="photo-upload-step__file-list">
              {mainPhoto && (
                <div className="photo-upload-step__file-item">
                  <FaTrashAlt onClick={removeMainPhoto} className="photo-upload-step__remove-icon" />
                  <span className="photo-upload-step__file-name">{mainPhoto.name}</span>
                </div>
              )}
            </div>
            <div className="photo-upload-step__upload-button-container">
              <input className="photo-upload-step__input-file" id="mainPhotoUpload" type="file" accept="image/*" onChange={handleMainPhotoUpload} />
              <label htmlFor="mainPhotoUpload" className="photo-upload-step__upload-button">
                <FaUpload className="photo-upload-step__upload-icon" /> 업로드
              </label>
            </div>
          </div>
          <span className="photo-upload-step__upload-status">{mainPhoto ? '1/1' : '0/1'}</span>
        </div>
      </div>
      <ImageGuide />
      <button className="photo-upload-step__next-button" onClick={handleNextStep} disabled={!mainPhoto}>
        다음
      </button>
    </div>
  );
};

export default PhotoUploadStep;