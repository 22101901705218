import React from 'react';
import { useNavigate } from 'react-router-dom';
import { getToken, removeToken } from '../../utils/auth'; // getToken, removeToken 유틸리티 함수 import
import './AccountTab.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const AccountTab = () => {
  const navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    // 여기에 비밀번호 변경 로직을 구현합니다.
    console.log('비밀번호 변경 요청');
  };

  const handleDeleteAccount = async () => {
    const confirmDelete = window.confirm('정말로 회원 탈퇴를 하시겠습니까?');
    if (confirmDelete) {
      try {
        const token = getToken(); // 토큰을 가져옴
        const response = await fetch(`${BACKEND_URL}/user/deleteUser`, {
          method: 'DELETE',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          removeToken(); // 로컬스토리지에서 토큰 제거
          alert('회원 탈퇴가 성공적으로 완료되었습니다.'); // 탈퇴 성공 메시지
          navigate('/'); // 메인 페이지로 이동
        } else {
          console.error('Failed to delete account');
          alert('회원 탈퇴에 실패했습니다.');
        }
      } catch (error) {
        console.error('Error deleting account:', error);
        alert('회원 탈퇴 중 오류가 발생했습니다.');
      }
    }
  };

  return (
    <div className="account-tab">
      <h2>회원정보 관리</h2>
      <form onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="currentPassword">현재 비밀번호</label>
          <input
            type="password"
            id="currentPassword"
            // value={currentPassword}
            // onChange={(e) => setCurrentPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="newPassword">새 비밀번호</label>
          <input
            type="password"
            id="newPassword"
            // value={newPassword}
            // onChange={(e) => setNewPassword(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="confirmPassword">새 비밀번호 확인</label>
          <input
            type="password"
            id="confirmPassword"
            // value={confirmPassword}
            // onChange={(e) => setConfirmPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="submit-button">비밀번호 변경</button>
      </form>
      <div className="account-actions">
        <button className="delete-account" onClick={handleDeleteAccount}>회원 탈퇴</button>
      </div>
    </div>
  );
};

export default AccountTab;