import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { getToken } from '../../utils/auth'; // getToken
import './ProfileTab.css';

// 이미지 파일 import
import defaultAvatar from '../../assets/images/icons/default-avatar.png';
import tokenIcon from '../../assets/images/icons/token-icon.png';
import subscriptionIcon from '../../assets/images/icons/subscription-icon.png';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ProfileTab = ({ userData }) => {
  const [listCounts, setListCounts] = useState({
    resumeCount: 0,
    photoCount: 0,
    questionCount: 0,
    interviewCount: 0,
    techTreeCount: 0,
    resumeEvaluationCount: 0,
  });

  // 서버로부터 목록 개수 가져오는 함수
  const fetchListCounts = async () => {
    const token = getToken(); // getToken 함수로 JWT 토큰 가져옴
  
    try {
      const response = await fetch(`${BACKEND_URL}/user/getlistcounts`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token, // JWT 토큰 헤더에 추가
        },
      });
  
      if (response.ok) {
        const data = await response.json();
        console.log('List Counts:', data); // 데이터가 제대로 들어오는지 확인
        setListCounts({
          resumeCount: data.coverLettersCount, // 백엔드에서 반환된 필드명을 사용
          photoCount: data.idPhotosCount,
          questionCount: data.interviewQuestionsCount,
          interviewCount: 0,  // 추후 면접기능 개발되면 변수명 user 스키마에 맞게 수정
          techTreeCount: data.techTreesCount,
          resumeEvaluationCount: data.resumeEvaluationsCount,
        });
      } else {
        console.error('Failed to fetch list counts');
      }
    } catch (error) {
      console.error('Error fetching list counts:', error);
    }
  };

  // 컴포넌트가 마운트될 때 목록 개수 요청
  useEffect(() => {
    fetchListCounts(); // 처음 마운트될 때만 fetch 호출
  }, []); // 빈 배열을 의존성으로 설정하여 useEffect가 한 번만 실행되도록 설정

  return (
    <div className="profile-tab">
      <div className="user-section">
        <div className="user-info">
          <img src={defaultAvatar} alt="User Avatar" className="avatar" />
          <div className="user-details">
            <h2>성명: {userData.username} 님</h2>
            <p>E-mail: {userData.email}</p>
            <p>닉네임: {userData.nickname}</p>
            <button className="edit-button">정보 수정하기</button>
          </div>
        </div>
        <div className="vertical-line"></div>
        <div className="user-stats">
          <div className="token-info">
            <img src={tokenIcon} alt="Token Icon" className="token-icon" />
            <div>
              <span>현재 보유한 토큰</span>
              <span className="token-amount">{userData.tokens}</span>
            </div>
            <button className="charge-button">토큰 충전하기</button>
          </div>
          <div className="subscription-info">
            <img src={subscriptionIcon} alt="Subscription Icon" className="subscription-icon" />
            <div>
              <span>구독권</span>
              <span className="subscription-plan">{userData.subscriptionPlan}</span>
            </div>
            <button className="view-subscription-button">구독정보 보기</button>
          </div>
        </div>
      </div>
      <h3>저장한 파일</h3>
      <div className="saved-files">
        <Link to="/mypage/saved-resumes" className="file-category">
          <span>자소서 생성기</span>
          <span>{listCounts.resumeCount} 건</span>
        </Link>
        <Link to="/mypage/saved-photos" className="file-category">
          <span>증명사진 생성기</span>
          <span>{listCounts.photoCount} 건</span>
        </Link>
        <Link to="/mypage/saved-questions" className="file-category">
          <span>면접예상질문 생성기</span>
          <span>{listCounts.questionCount} 건</span>
        </Link>
        <Link to="/mypage/saved-interviews" className="file-category">
          <span>AI 면접 기록</span>
          <span>{listCounts.interviewCount} 건</span>
        </Link>
        <Link to="/mypage/saved-tech-trees" className="file-category">
          <span>테크트리 생성기</span>
          <span>{listCounts.techTreeCount} 건</span>
        </Link>
        <Link to="/mypage/saved-resume-evaluations" className="file-category">
          <span>이력서 평가</span>
          <span>{listCounts.resumeEvaluationCount} 건</span>
        </Link>
      </div>
    </div>
  );
};

export default ProfileTab;