import React from 'react';
import './PrivacyTab.css';

const PrivacyTab = () => {
  return (
    <div className="privacy-tab">
      <h2>개인정보 처리방침</h2>
      <div className="privacy-content">
        <section>
          <h3>1. 개인정보의 수집 및 이용 목적</h3>
          <p>당사는 다음과 같은 목적으로 개인정보를 수집 및 이용합니다:</p>
          <ul>
            <li>서비스 제공 및 계정 관리</li>
            <li>고객 지원 및 문의 응대</li>
            <li>서비스 개선 및 신규 서비스 개발</li>
          </ul>
        </section>
        <section>
          <h3>2. 수집하는 개인정보 항목</h3>
          <p>당사가 수집하는 개인정보 항목은 다음과 같습니다:</p>
          <ul>
            <li>이름, 이메일 주소, 전화번호</li>
            <li>서비스 이용 기록, 접속 로그, 쿠키</li>
          </ul>
        </section>
        <section>
          <h3>3. 개인정보의 보유 및 이용 기간</h3>
          <p>개인정보는 회원 탈퇴 시 또는 수집 및 이용 목적 달성 시까지 보유 및 이용됩니다.</p>
        </section>
      </div>
    </div>
  );
};

export default PrivacyTab;
