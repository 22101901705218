import React from 'react';
import guide1 from '../../../assets/images/photosgenerator/guide1.png';
import guide2 from '../../../assets/images/photosgenerator/guide2.png';
import guide3 from '../../../assets/images/photosgenerator/guide3.png';
import guide4 from '../../../assets/images/photosgenerator/guide4.png';
import guide5 from '../../../assets/images/photosgenerator/guide5.png';

const ImageGuide = () => {
  return (
    <div className="image-guide">
      <h2 className="image-guide__title">사진 규격 가이드</h2>
      <div className="image-guide__images">
        <div className="image-guide__image-item">
          <img src={guide1} alt="guide1" />
          <div className="image-guide__description invalid">부적절해요</div>
          <div className="image-guide__text">얼굴이 가려지거나 잘린 사진, 얼굴이 너무 멀리 있는 사진, 정면이 아닌 사진 등</div>
        </div>
        <div className="image-guide__image-item">
          <img src={guide2} alt="guide2" />
          <div className="image-guide__description invalid">부적절해요</div>
          <div className="image-guide__text">얼굴이 가려지거나 잘린 사진, 얼굴이 너무 멀리 있는 사진, 정면이 아닌 사진 등</div>
        </div>
        <div className="image-guide__image-item">
          <img src={guide3} alt="guide3" />
          <div className="image-guide__description invalid">부적절해요</div>
          <div className="image-guide__text">얼굴이 가려지거나 잘린 사진, 얼굴이 너무 멀리 있는 사진, 정면이 아닌 사진 등</div>
        </div>
        <div className="image-guide__image-item">
          <img src={guide4} alt="guide4" />
          <div className="image-guide__description valid">좋아요!</div>
          <div className="image-guide__text">증명사진과 비슷한 규격의 사진, 정면 사진</div>
        </div>
        <div className="image-guide__image-item">
          <img src={guide5} alt="guide5" />
          <div className="image-guide__description valid">좋아요!</div>
          <div className="image-guide__text">증명사진과 비슷한 규격의 사진, 정면 사진</div>
        </div>
      </div>
    </div>
  );
};

export default ImageGuide;