import React, { useState, useEffect } from 'react';
import { getToken } from '../../utils/auth';
import './CommentSection.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const CommentSection = ({ postId }) => {
    const [comments, setComments] = useState([]);
    const [newComment, setNewComment] = useState('');
    const [replyingTo, setReplyingTo] = useState(null);
    const [replyText, setReplyText] = useState('');
    const token = getToken();
    const [currentUser, setCurrentUser] = useState(null);

    useEffect(() => {
        fetchComments();
        fetchCurrentUser();
    }, [postId]);

    const fetchComments = async () => {
        try {
            const response = await fetch(`${BACKEND_URL}/posts/${postId}/comments`, {
                headers: { 'x-auth-token': token }
            });
            if (response.ok) {
                const data = await response.json();
                const organizedComments = organizeComments(data);
                setComments(organizedComments);
            } else {
                console.error('댓글을 가져오는데 실패했습니다');
            }
        } catch (error) {
            console.error('댓글 가져오기 오류:', error);
        }
    };

    const fetchCurrentUser = () => {
        const token = getToken();
        if (token) {
            const payload = JSON.parse(atob(token.split('.')[1]));
            setCurrentUser(payload.user);
        }
    };

    const organizeComments = (flatComments) => {
        const commentMap = {};
        const rootComments = [];

        flatComments.forEach(comment => {
            comment.replies = [];
            commentMap[comment._id] = comment;
        });

        flatComments.forEach(comment => {
            if (comment.parentReply) {
                const parent = commentMap[comment.parentReply];
                if (parent) {
                    parent.replies.push(comment);
                } else {
                    rootComments.push(comment);
                }
            } else {
                rootComments.push(comment);
            }
        });

        return rootComments;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BACKEND_URL}/posts/${postId}/comments`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                },
                body: JSON.stringify({ 
                    text: newComment
                })
            });
            if (response.ok) {
                setNewComment('');
                fetchComments();
            } else {
                console.error('댓글 작성에 실패했습니다');
            }
        } catch (error) {
            console.error('댓글 작성 오류:', error);
        }
    };

    const handleReplySubmit = async (e, parentId) => {
        e.preventDefault();
        try {
            const response = await fetch(`${BACKEND_URL}/posts/${postId}/comments/${parentId}/replies`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                },
                body: JSON.stringify({ 
                    text: replyText
                })
            });
            if (response.ok) {
                setReplyText('');
                setReplyingTo(null);
                fetchComments();
            } else {
                console.error('답글 작성에 실패했습니다');
            }
        } catch (error) {
            console.error('답글 작성 오류:', error);
        }
    };

    const handleReaction = async (commentId, reactionType) => {
        try {
            const response = await fetch(`${BACKEND_URL}/posts/${postId}/comments/${commentId}/reaction`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'x-auth-token': token
                },
                body: JSON.stringify({ reactionType })
            });
            if (response.ok) {
                fetchComments();
            } else {
                console.error('리액션 추가에 실패했습니다');
            }
        } catch (error) {
            console.error('리액션 추가 오류:', error);
        }
    };

    const handleDeleteComment = async (commentId) => {
        if (window.confirm('정말로 이 댓글을 삭제하시겠습니까?')) {
            try {
                const response = await fetch(`${BACKEND_URL}/posts/${postId}/comments/${commentId}`, {
                    method: 'DELETE',
                    headers: {
                        'x-auth-token': token
                    }
                });
                if (response.ok) {
                    fetchComments();
                } else {
                    console.error('댓글 삭제에 실패했습니다');
                }
            } catch (error) {
                console.error('댓글 삭제 오류:', error);
            }
        }
    };

    const renderComment = (comment, depth = 0) => (
        <div key={comment._id} className={`comment depth-${depth}`}>
            <div className="comment-content">
                <div className="comment-header">
                    <span className="comment-author">{comment.user.username}</span>
                    <span className="comment-date">{new Date(comment.createdAt).toLocaleString()}</span>
                </div>
                <p className={`comment-text ${comment.isDeleted ? 'deleted' : ''}`}>{comment.text}</p>
                {!comment.isDeleted && (
                    <div className="comment-actions">
                        <button onClick={() => handleReaction(comment._id, 'like')} className="reaction-button">
                            👍 {comment.likes ? comment.likes.length : 0}
                        </button>
                        <button onClick={() => setReplyingTo(comment._id)} className="reply-button">
                            {depth === 0 ? '💬 답글' : '↪️ 답글'}
                        </button>
                        {currentUser && currentUser.id === comment.user._id && (
                            <button onClick={() => handleDeleteComment(comment._id)} className="delete-button">
                                🗑️ 삭제
                            </button>
                        )}
                    </div>
                )}
            </div>
            {replyingTo === comment._id && (
                <form onSubmit={(e) => handleReplySubmit(e, comment._id)} className="reply-form">
                    <textarea
                        value={replyText}
                        onChange={(e) => setReplyText(e.target.value)}
                        placeholder="답글을 입력하세요"
                        required
                    />
                    <div className="reply-form-actions">
                        <button type="submit">답글 작성</button>
                        <button type="button" onClick={() => setReplyingTo(null)}>취소</button>
                    </div>
                </form>
            )}
            {comment.replies && comment.replies.length > 0 && (
                <div className="replies">
                    {comment.replies.map(reply => renderComment(reply, depth + 1))}
                </div>
            )}
        </div>
    );

    return (
        <div className="comment-section">
            <h3>댓글 {comments.length}</h3>
            <form onSubmit={handleSubmit} className="comment-form">
                <textarea
                    value={newComment}
                    onChange={(e) => setNewComment(e.target.value)}
                    placeholder="댓글을 입력하세요"
                    required
                />
                <button type="submit">댓글 작성</button>
            </form>
            <div className="comments-list">
                {comments.map(comment => renderComment(comment))}
            </div>
        </div>
    );
};

export default CommentSection;