import React, { useState, useEffect } from 'react';
import { FaHome, FaSearch, FaComments, FaUser, FaBell, FaCog, FaSignOutAlt, FaBars, FaChevronDown, FaChevronUp, FaCoins } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import './Sidebar.css';
import AlertPopup from './AlertPopup';
import { getToken, removeToken } from '../utils/auth';

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const Sidebar = ({ isOpen, toggleSidebar }) => {
  const [isToolsOpen, setIsToolsOpen] = useState(false);
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [username, setUsername] = useState('');
  const [tokens, setTokens] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = getToken();
        const response = await fetch(`${BACKEND_URL}/user`, {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'x-auth-token': token,
          },
        });

        if (response.ok) {
          const data = await response.json();
          setUsername(data.username);
          setTokens(data.tokens);
        } else {
          console.error('Failed to fetch user data');
        }
      } catch (error) {
        console.error('Error fetching user data:', error);
      }
    };

    fetchUserData();
  }, []);

  const toggleToolsMenu = () => {
    setIsToolsOpen(!isToolsOpen);
  };

  const togglePopup = () => {
    setIsPopupOpen(!isPopupOpen);
  };

  const handleLogout = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/auth/logout`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': getToken(),
        },
      });

      if (response.ok) {
        removeToken();
        alert('로그아웃되었습니다.');
        navigate('/');
      } else {
        alert('로그아웃 실패. 다시 시도해주세요.');
      }
    } catch (error) {
      console.error('Error during logout:', error);
      alert('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const handleTokenClick = () => {
    navigate('/payment');
  };

  return (
    <div className={`sidebar ${isOpen ? 'sidebar-open' : 'sidebar-closed'}`}>
      <div className="sidebar-logo">
        <FaBars onClick={toggleSidebar} />
        {isOpen && (
          <span onClick={() => navigate('/main')} style={{ cursor: 'pointer' }}>
            Jobmaker
          </span>
        )}
      </div>
      <ul className="sidebar-menu">
        <li>
          <div className="sidebar-menu-item" onClick={toggleToolsMenu}>
            <FaHome />
            {isOpen && <span>취업도구</span>}
            {isOpen && (isToolsOpen ? <FaChevronUp /> : <FaChevronDown />)}
          </div>
          {isToolsOpen && (
            <ul className="sidebar-submenu">
              <li>
                <Link to="/tools/resume-generator/setinfo">
                  {isOpen && <span>자소서 생성기</span>}
                </Link>
              </li>
              <li>
                <Link to="/tools/photo-generator">
                  {isOpen && <span>증명사진 생성기</span>}
                </Link>
              </li>
              <li>
                <Link to="/tools/questions-setInfo">
                  {isOpen && <span>면접예상질문 생성기</span>}
                </Link>
              </li>
              <li>
                <Link to="/tools/tech-tree-generator">
                  {isOpen && <span>테크트리 생성기</span>}
                </Link>
              </li>
              <li>
                <Link to="/tools/job-matching">
                  {isOpen && <span>잡 매칭</span>}
                </Link>
              </li>
              <li>
                <Link to="/tools/ai-interviewer">
                  {isOpen && <span>AI 면접관</span>}
                </Link>
              </li>
              <li>
                <Link to="/tools/resume-evaluation">
                  {isOpen && <span>이력서 평가</span>}
                </Link>
              </li>
            </ul>
          )}
        </li>
        <li>
          <Link to="/recruit/info">
            <FaSearch />
            {isOpen && <span>채용정보</span>}
          </Link>
        </li>
        <li>
          <Link to="/community">
            <FaComments />
            {isOpen && <span>커뮤니티</span>}
          </Link>
        </li>
        <li>
          <Link to="/mypage">
            <FaUser />
            {isOpen && <span>마이페이지</span>}
          </Link>
        </li>
        <li className="sidebar-menu-item" onClick={togglePopup} style={{ cursor: 'pointer' }}>
          <FaBell />
          {isOpen && <span>나의 알림</span>}
        </li>
      </ul>
      <div className="sidebar-user-info">
        <div className="sidebar-username">
          <FaUser />
          <span>{username} 님</span>
        </div>
        <div className="sidebar-tokens" onClick={handleTokenClick}>
          <FaCoins />
          <span>{tokens}</span>
        </div>
        <div className="sidebar-settings">
          <button className="sidebar-settings-btn">
            <FaCog />
            {isOpen && <span>계정 설정</span>}
          </button>
          <button className="sidebar-logout-btn" onClick={handleLogout}>
            <FaSignOutAlt />
            {isOpen && <span>로그아웃</span>}
          </button>
        </div>
      </div>
      {isPopupOpen && <AlertPopup onClose={togglePopup} />}
    </div>
  );
};

export default Sidebar;