import React, { useState } from 'react';
import styles from './TechTreeGeneratorPage.module.css';  // 통합된 CSS 모듈 import

const TechTreeResult = ({ recommendations, jobTitle, role, responsibilities, onCopy, onSave }) => {
    const [showPopup, setShowPopup] = useState(false);
    const [popupMessage, setPopupMessage] = useState('');

    if (!recommendations || typeof recommendations !== 'object') {
        return <div className="loading-message">데이터를 불러오는 중입니다...</div>;
    }

    const { programs = [], certifications = [], skills = [] } = recommendations;

    const renderSection = (title, items) => (
        <div className={styles.recommendationSection}>
            <h4>{title}</h4>
            {items.length > 0 ? (
                <ul>
                    {items.map((item, index) => (
                        <li key={index}>
                            <strong>{item.name}</strong>
                            {item.description && <p>{item.description}</p>}
                            {item.issuing_organization && <p>발행 기관: {item.issuing_organization}</p>}
                        </li>
                    ))}
                </ul>
            ) : (
                <p>추천 항목이 없습니다.</p>
            )}
        </div>
    );

    const handleCopy = () => {
        onCopy();
        setPopupMessage('복사되었습니다!');
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
    };

    const handleSave = () => {
        onSave();
        setPopupMessage('저장되었습니다!');
        setShowPopup(true);
        setTimeout(() => setShowPopup(false), 2000);
    };

    return (
        <div className={styles.techTreeResult}>
            <h3>{`${jobTitle} 직종의 ${role} 직무에서 ${responsibilities}을(를) 담당한다면...`}</h3>
            
            {renderSection("이런 프로그램을 다루면 좋아요!", programs)}
            {renderSection("이런 자격증을 보유하면 좋아요!", certifications)}
            {renderSection("이런 역량을 키우면 좋아요!", skills)}

            <div className={styles.buttonGroup}>
                <button onClick={handleCopy} className={styles.copyBtn}>텍스트 복사하기</button>
                <button onClick={handleSave} className={styles.saveBtn}>저장하기</button>
            </div>

            {showPopup && (
                <div className={styles.popup}>
                    <p>{popupMessage}</p>
                </div>
            )}
        </div>
    );
};

export default TechTreeResult;
