import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import './SavedPhotos.css';

const SavedPhotos = ({ userData }) => {
  const [photos, setPhotos] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const mockPhotos = [
      { title: '사진 1', date: '2024.07.20' },
      { title: '사진 2', date: '2024.07.20' },
    ];

    setTimeout(() => {
      setPhotos(mockPhotos);
      setLoading(false);
    }, 1000);
  }, []);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="saved-photos">
      <div className="header">
        <Link to="/mypage" className="back-link">
          <img src="back-icon.png" alt="Back" className="back-icon" />
          저장한 파일
        </Link>
        <h2>증명사진 생성기</h2>
      </div>
      <div className="photo-list">
        {photos.map((photo, index) => (
          <div className="photo-item" key={index}>
            <span>{photo.title}</span>
            <span>{photo.date}</span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SavedPhotos;
