import React, { useState, useEffect } from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { getToken } from '../../utils/auth';
import CommentSection from './CommentSection';
import './PostDetail.css';

const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const PostDetail = () => {
  const { postId } = useParams();
  const [post, setPost] = useState(null);
  const [liked, setLiked] = useState(false);
  const [loading, setLoading] = useState(true);
  const [isAuthor, setIsAuthor] = useState(false);
  const token = getToken();
  const navigate = useNavigate();

  useEffect(() => {
    fetchPostDetail();
    fetchLikeStatus();
  }, [postId]);

  const fetchPostDetail = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/posts/${postId}`, {
        headers: { 'x-auth-token': token }
      });
      if (response.ok) {
        const data = await response.json();
        setPost(data);
        setIsAuthor(data.user === JSON.parse(atob(token.split('.')[1])).user.id);
      } else {
        console.error('게시물을 가져오는데 실패했습니다');
      }
    } catch (error) {
      console.error('게시물 가져오기 오류:', error);
    } finally {
      setLoading(false);
    }
  };

  const fetchLikeStatus = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/posts/${postId}/like`, {
        headers: { 'x-auth-token': token }
      });
      if (response.ok) {
        const data = await response.json();
        setLiked(data.liked);
      }
    } catch (error) {
      console.error('좋아요 상태 가져오기 오류:', error);
    }
  };

  const handleLike = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/posts/like`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'x-auth-token': token
        },
        body: JSON.stringify({ postId })
      });
      if (response.ok) {
        const data = await response.json();
        setLiked(data.liked);
        setPost(prev => ({ ...prev, likeCount: data.liked ? prev.likeCount + 1 : prev.likeCount - 1 }));
      }
    } catch (error) {
      console.error('좋아요 처리 오류:', error);
    }
  };

  const handleDelete = async () => {
    if (window.confirm('정말로 이 게시물을 삭제하시겠습니까?')) {
      try {
        const response = await fetch(`${BACKEND_URL}/posts/${postId}`, {
          method: 'DELETE',
          headers: { 'x-auth-token': token }
        });
        if (response.ok) {
          navigate('/community');
        } else {
          console.error('게시물 삭제에 실패했습니다');
        }
      } catch (error) {
        console.error('게시물 삭제 오류:', error);
      }
    }
  };

  if (loading) {
    return <div>로딩 중...</div>;
  }

  if (!post) {
    return <div>게시물이 존재하지 않습니다.</div>;
  }

  return (
    <div className="post-detail-container">
      <Link to="/community" className="back-button">← 전체 게시글</Link>
      <div className="post-detail">
        <h1 className="post-title">{post.title}</h1>
        <div className="post-meta">
          <span className="author">{post.author}</span>
          <span className="date">{new Date(post.createdAt).toLocaleString()}</span>
          <span className="views">조회 {post.views}</span>
        </div>
        <p className="post-content">{post.text}</p>
        <div className="post-actions">
          <button onClick={handleLike} className={`action-button ${liked ? 'liked' : ''}`}>
            👍 좋아요 {post.likeCount}
          </button>
          <span className="comment-count">💬 댓글 {post.commentCount}</span>
          {isAuthor && (
            <div className="author-actions">
              <Link to={`/community/edit/${postId}`} className="edit-button">수정</Link>
              <button onClick={handleDelete} className="delete-button">삭제</button>
            </div>
          )}
        </div>
      </div>
      <CommentSection postId={postId} />
    </div>
  );
};

export default PostDetail;