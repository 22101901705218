import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import styles from './QuestionSetInfoPage1.module.css'; // CSS Modules import

const QuestionSetInfoPage1 = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const initialData = location.state?.formData || {
    coverLetter: '',
    skills: '',
    experiences: ''
  };

  const initialSkillsList = location.state?.formData.skills || [];
  const initialExperiencesList = location.state?.formData.experiences || [];

  const [formData, setFormData] = useState(initialData);
  const [skillsList, setSkillsList] = useState(initialSkillsList);
  const [experiencesList, setExperiencesList] = useState(initialExperiencesList);
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prevState => ({ ...prevState, [name]: value }));
  };

  const handleAddSkill = () => {
    if (formData.skills.trim()) {
      setSkillsList(prevList => [...prevList, formData.skills]);
      setFormData(prevState => ({ ...prevState, skills: '' }));
    }
  };

  const handleAddExperience = () => {
    if (formData.experiences.trim()) {
      setExperiencesList(prevList => [...prevList, formData.experiences]);
      setFormData(prevState => ({ ...prevState, experiences: '' }));
    }
  };

  const handleKeyPress = (e, fieldType) => {
    if (e.key === 'Enter') {
      e.preventDefault();
      if (fieldType === 'skills') {
        handleAddSkill();
      } else if (fieldType === 'experiences') {
          handleAddExperience();
      }
    }
  };

  const handleDeleteSkill = (index) => {
    setSkillsList(prevList => prevList.filter((_, i) => i !== index));
  };

  const handleDeleteExperience = (index) => {
    setExperiencesList(prevList => prevList.filter((_, i) => i !== index));
  };

  useEffect(() => {
    const allFieldsFilled = formData.coverLetter.trim() !== '' &&
                            skillsList.length > 0 &&
                            experiencesList.length > 0;
    setIsButtonDisabled(!allFieldsFilled);
  }, [formData, skillsList, experiencesList]);

  const handleNextClick = () => {
    const dataToSend = {
      coverLetter: formData.coverLetter,
      skills: skillsList,
      experiences: experiencesList
    };
    navigate('/tools/questions-setCategory', { state: { formData: dataToSend } });
  };

  return (
    <div className={styles.questionSetInfoPage}>
      <h2>면접 예상질문 생성기</h2>
      <p>정보 입력 (1/2 Step)</p>
      <form>
        <label>
          자기소개서 업로드 - 텍스트 또는 Pdf 파일 업로드
          <textarea
            name="coverLetter"
            value={formData.coverLetter}
            onChange={handleChange}
            placeholder="자기소개서를 입력해주세요."
            maxLength="4000"
            className={styles.textarea}
          />
        </label>
        <label>
          보유 기술 및 역량
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="skills"
              value={formData.skills}
              onChange={handleChange}
              onKeyPress={(e) => handleKeyPress(e, 'skills')}
              placeholder="예시) ○○ 자격증 보유"
              maxLength="30"
              className={styles.input}
            />
            <button type="button" onClick={handleAddSkill}>+</button>
          </div>
          <div className={styles.tagList}>
            {skillsList.map((skill, index) => (
              <span key={index} className={styles.tag}>
                {skill} <button type="button" onClick={() => handleDeleteSkill(index)}>x</button>
              </span>
            ))}
          </div>
        </label>
        <label>
          경력 사항
          <div className={styles.inputGroup}>
            <input
              type="text"
              name="experiences"
              value={formData.experiences}
              onChange={handleChange}
              onKeyPress={(e) => handleKeyPress(e, 'experiences')}
              placeholder="예시) it기업에서 인턴 5개월"
              maxLength="30"
              className={styles.input}
            />
            <button type="button" onClick={handleAddExperience}>+</button>
          </div>
          <div className={styles.tagList}>
            {experiencesList.map((experience, index) => (
              <span key={index} className={styles.tag}>
                {experience} <button type="button" onClick={() => handleDeleteExperience(index)}>x</button>
              </span>
            ))}
          </div>
        </label>
        <button type="button" className={`${styles.nextBtn} ${isButtonDisabled ? styles.disabled : ''}`} onClick={handleNextClick} disabled={isButtonDisabled}>다음</button>
      </form>
    </div>
  );
};

export default QuestionSetInfoPage1;
