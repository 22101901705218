import React, { useState } from 'react';
// fetch 방식으로 요청을 보내고 있으므로 axios import는 삭제함
import { useNavigate } from 'react-router-dom';
import './ResetPasswordPage.css';

// 환경 변수에서 백엔드 URL 가져오기
const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

const ResetPasswordPage = () => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [verificationMessage, setVerificationMessage] = useState('');
  const [codeSentMessage, setCodeSentMessage] = useState('');
  const [codeVerified, setCodeVerified] = useState(false);
  const navigate = useNavigate();
  const [token, setToken] = useState('');

  const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,20}$/;

  const handleSendVerificationCode = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/auth/send-verification-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email }),
      });

      const data = await response.json();
      if (response.ok) {
        setCodeSentMessage('인증코드가 전송되었습니다.');
        setToken(data.token); // JWT 토큰 저장
      } else {
        setCodeSentMessage(data.msg);
      }
    } catch (error) {
      console.error('Error sending verification code:', error);
      setCodeSentMessage('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const handleVerifyCode = async () => {
    try {
      const response = await fetch(`${BACKEND_URL}/auth/verify-email-code`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ inputCode: verificationCode, token }), // JWT 토큰과 인증 코드를 함께 전송
      });

      const data = await response.json();
      if (data.valid) {
        setCodeVerified(true);
        setVerificationMessage('인증이 완료되었습니다.');
      } else {
        setCodeVerified(false);
        setVerificationMessage('인증번호가 다릅니다.');
      }
    } catch (error) {
      console.error('Error verifying code:', error);
      setVerificationMessage('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  const handleResetPassword = async (e) => {
    e.preventDefault();

    if (!passwordRegex.test(newPassword)) {
      return alert('비밀번호는 8자 이상 20자 이하, 특수문자 1개 이상, 영문, 숫자를 포함해야 합니다.');
    }

    if (newPassword !== confirmPassword) {
      return alert('새 비밀번호가 일치하지 않습니다.');
    }

    if (!codeVerified) {
      return alert('인증이 완료되지 않았습니다.');
    }

    try {
      const response = await fetch(`${BACKEND_URL}/auth/reset-password`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, verificationCode, newPassword, token }), // JWT 토큰과 새로운 비밀번호 전송
      });

      const data = await response.json();
      if (response.ok) {
        alert('비밀번호가 성공적으로 변경되었습니다.');
        navigate('/login');
      } else {
        setVerificationMessage(data.msg);
      }
    } catch (error) {
      console.error('Error resetting password:', error);
      setVerificationMessage('서버 오류가 발생했습니다. 잠시 후 다시 시도해주세요.');
    }
  };

  return (
    <div className="reset-password-page">
      <h1>비밀번호 재설정</h1>
      <form onSubmit={handleResetPassword}>
        <input
          type="email"
          placeholder="가입한 이메일 주소를 입력해주세요"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
        />
        <button type="button" onClick={handleSendVerificationCode}>
          인증코드 전송
        </button>
        {codeSentMessage && (
          <div className="code-sent-message">
            {codeSentMessage}
          </div>
        )}
        <div className="verification-section">
          <input
            type="text"
            placeholder="인증코드를 입력해주세요"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
          />
          <button type="button" onClick={handleVerifyCode}>
            인증코드 확인
          </button>
        </div>
        {verificationMessage && (
          <div className={verificationMessage.includes('완료') ? 'verification-success' : 'verification-failure'}>
            {verificationMessage}
          </div>
        )}
        {codeVerified && (
          <>
            <input
              type="password"
              placeholder="새 비밀번호를 입력해주세요"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              required
            />
            <input
              type="password"
              placeholder="새 비밀번호를 다시 입력해주세요"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              required
            />
            {confirmPassword && (
              <div className={newPassword === confirmPassword ? 'password-match' : 'password-mismatch'}>
                {newPassword === confirmPassword ? '패스워드가 동일합니다' : '패스워드가 틀립니다'}
              </div>
            )}
            <button type="submit">비밀번호 재설정</button>
          </>
        )}
      </form>
    </div>
  );
};

export default ResetPasswordPage;