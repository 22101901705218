import React, { useState } from 'react';
import './AlertPopup.css'; // Import the CSS for styling

const AlertPopup = ({ onClose }) => {
  const [isDragging, setIsDragging] = useState(false);
  const [offset, setOffset] = useState({ x: 0, y: 0 });
  const [position, setPosition] = useState({ top: 100, left: 100 }); // Initial position

  const handleMouseDown = (e) => {
    setIsDragging(true);
    setOffset({
      x: e.clientX - position.left,
      y: e.clientY - position.top,
    });
  };

  const handleMouseUp = () => {
    setIsDragging(false);
  };

  const handleMouseMove = (e) => {
    if (isDragging) {
      setPosition({
        left: e.clientX - offset.x,
        top: e.clientY - offset.y,
      });
    }
  };

  return (
    <div
      className="popup"
      style={{ top: position.top, left: position.left }}
      onMouseMove={handleMouseMove}
      onMouseUp={handleMouseUp}
      onMouseLeave={handleMouseUp} // Stop dragging if mouse leaves
    >
      <div
        className="popup-header"
        onMouseDown={handleMouseDown}
        style={{ cursor: 'grab' }}
      >
        <span>나의 알림</span>
        <button onClick={onClose} className="close-button">&times;</button>
      </div>
      <div className="popup-content">
        <p>여기에 알림 내용이 표시됩니다.</p>
      </div>
    </div>
  );
};

export default AlertPopup;
